import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import GenericModal, { GenericModalTheme } from '../common/GenericModal'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import TelegramIcon from '@material-ui/icons/Telegram'
import CC_DT from '../../assets/certs/CT_CC (Discovery Trail).png'
import CC_LT from '../../assets/certs/CT_CC (Legacy Trail).png'
import CC_BM from '../../assets/certs/CT_CC (Beyond Masungi).png'
import CC_TP_1 from '../../assets/certs/TP_CC (Acacia).png'
import CC_TP_2 from '../../assets/certs/TP_CC (Lauan).png'
import CC_TP_3 from '../../assets/certs/TP_CC (Malaruhat).png'
import CC_TP_4 from '../../assets/certs/TP_CC (Narra).png'
import CC_TP_5 from '../../assets/certs/TP_CC (Putat).png'
import CC_TP_6 from '../../assets/certs/TP_CC (Salingogon).png'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton
} from 'react-share'
import { AppContext } from '../states/AppContextProvider'
import { Student } from '../../models/Student'
import { TrailType } from '../../config/enums'
import {
  badgeForTrailType,
  badgeOfficialName,
  getBadgeDone
} from '../../services/StudentService'
import { getStudentProfile } from '../../services/UserService'
import { ActionType } from '../states/AppReducer'
import { styles } from '@material-ui/pickers/views/Calendar/Calendar'

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      backgroundColor: '#686039',
      color: 'white'
    },
    spacedButton: {
      marginLeft: '1rem'
    },
    mainContent: {
      paddingLeft: '0',
      paddingRight: '0',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '10rem',
        paddingRight: '10rem'
      }
    },
    badge: {
      width: '75%',
      [theme.breakpoints.up('md')]: {
        width: '40%'
      }
    },
    downloadContainer: {
      textAlign: 'center',
      marginTop: '1rem',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        marginTop: 0
      }
    },
    socialContainer: {
      justifyContent: 'center',
      marginTop: '1rem',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        marginTop: 0
      }
    }
  })
)

interface StartTrailProps {
  type: TrailType
  onClose: () => void
}

const GetBadge = ({ type, onClose }: StartTrailProps) => {
  const classes = useStyles()
  const context = useContext(AppContext)
  const profile = context.state.userProfile as Student
  const badge = badgeForTrailType(type)

  useEffect(() => {
    const badgeTaken = async () => {
      // Update student score
      await getBadgeDone(profile.id, profile.ranking, type)

      // Refetch profile to reflect changes on UI
      const studentProfile = await getStudentProfile(context.state.user!.uid)
      context.dispatch({
        type: ActionType.setUserProfile,
        payload: studentProfile
      })
    }

    badgeTaken()
  }, [])

  const badgeShareUrl = encodeURI(
    `${window.location.origin}/badges/${badge}/${btoa(profile.email)}`
  )

  const shareMessageFb = `${profile.firstName} ${
    profile.lastName
  } just received an honorary Masungi Georeserve ${badgeOfficialName(
    badge
  )} badge! Take our trail challenges to get your own badge and collect more. Ask your school to visit Masungi 360° to start your virtual field trip!`

  const shareMessageTw = `I just received an honorary Masungi Georeserve ${badgeOfficialName(
    badge
  )} badge! Ask your school to visit Masungi 360° to start your virtual field trip!`

  const getCertificate = () => {
    if (type === TrailType.DiscoveryTrail) {
      return CC_DT
    } else if (type === TrailType.LegacyTrail) {
      return CC_LT
    } else if (type === TrailType.BeyondMasungi) {
      return CC_BM
    } else {
      const certs = [CC_TP_1, CC_TP_2, CC_TP_3, CC_TP_4, CC_TP_5, CC_TP_6]
      const randomIndex = Math.floor(Math.random() * 5)
      return certs[randomIndex]
    }
  }

  return (
    <GenericModal
      theme={GenericModalTheme.black}
      title="Get Badge"
      onClose={onClose}
    >
      <Box textAlign="center" mt={2} className={classes.mainContent}>
        <Typography>
          Congratulations, {profile.firstName} {profile.lastName}! You are now
          an honorary Masungi Georeserve <b>{badgeOfficialName(badge)}</b>. You
          may download your certificate, or share this achievement on social
          media!
        </Typography>
        <Box mt={3}>
          <img
            className={classes.badge}
            src={`/badges/${badge}.png`}
            alt={badge}
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.downloadContainer}>
            <Button
              variant="contained"
              className={classes.button}
              disableElevation={true}
              href={getCertificate()}
              target="_blank"
              download={true}
            >
              Download Certificate
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            className={classes.socialContainer}
          >
            <FacebookShareButton
              url={badgeShareUrl}
              quote={shareMessageFb}
              hashtag={`#masungi360 #${badge.replace(/ /g, '')}`}
            >
              <Button
                variant="contained"
                className={classes.button}
                disableElevation={true}
              >
                <FacebookIcon />
              </Button>
            </FacebookShareButton>

            <TwitterShareButton title={shareMessageTw} url={badgeShareUrl}>
              <Button
                variant="contained"
                className={`${classes.button} ${classes.spacedButton}`}
                disableElevation={true}
              >
                <TwitterIcon />
              </Button>
            </TwitterShareButton>

            <TelegramShareButton title={shareMessageTw} url={badgeShareUrl}>
              <Button
                variant="contained"
                className={`${classes.button} ${classes.spacedButton}`}
                disableElevation={true}
              >
                <TelegramIcon />
              </Button>
            </TelegramShareButton>
          </Grid>
        </Grid>
      </Box>
    </GenericModal>
  )
}

export default GetBadge
