import React, { useContext, useEffect, useState } from 'react'
import GenericModal, { GenericModalTheme } from '../common/GenericModal'
import {
  Box,
  Button,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core'
import { AppContext } from '../states/AppContextProvider'
import { getStudentProfile } from '../../services/UserService'
import { ActionType } from '../states/AppReducer'
import { Student } from '../../models/Student'
import { TrailType } from '../../config/enums'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CheckIcon from '@material-ui/icons/Check'
import WrongIcon from '@material-ui/icons/Close'
import { red, green, grey } from '@material-ui/core/colors'
import {
  badgeForTrailType,
  takeChallengeDone
} from '../../services/StudentService'
import { Question } from '../../models/Question'

const useStyles = makeStyles({
  stepper: {
    backgroundColor: '#514d2f',
    padding: 5,
    marginBottom: '1rem',
    borderRadius: 16
  },
  img: {
    minHeight: 300,
    maxWidth: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
  }
})

interface TakeChallengeProps {
  type: TrailType
  onClose: () => void
  onComplete: (doNextStep: boolean) => void
}

const questionsFile = require('../../config/questions.json')

const TakeChallenge = ({ type, onClose, onComplete }: TakeChallengeProps) => {
  const classes = useStyles()
  const context = useContext(AppContext)
  const [activeStep, setActiveStep] = useState(0)
  const [answers, setAnswers] = useState<string[]>([])
  const [score, setScore] = useState(0)
  const questions = questionsFile[type] as Question[]
  const passingGrade = Math.round(questions.length * 0.7)
  const badge = badgeForTrailType(type)

  let trailName = 'Discovery Trail'
  if (type === TrailType.LegacyTrail) {
    trailName = 'Legacy Trail'
  } else if (type === TrailType.BeyondMasungi) {
    trailName = 'Beyond Masungi'
  }

  useEffect(() => {
    let newScore = 0
    answers.forEach((answer, i) => {
      if (checkAnswer(i)) {
        newScore += 1
      }
    })
    setScore(newScore)
  }, [answers])

  useEffect(() => {
    if (activeStep < questions.length) return

    const saveScore = async () => {
      const profile = context.state.userProfile as Student
      if (!profile) return

      // Update student score
      await takeChallengeDone(profile.id, type, score)

      // Refetch profile to reflect changes on UI
      const studentProfile = await getStudentProfile(context.state.user!.uid)
      context.dispatch({
        type: ActionType.setUserProfile,
        payload: studentProfile
      })
    }

    saveScore().then()
  }, [activeStep])

  const checkAnswer = (step: number) => {
    if (step > answers.length) return false
    if (step > questions.length) return false

    const answer = answers[step]
    const question = questions[step]

    if (answer === question.answer) {
      return true
    } else {
      return false
    }
  }

  const reset = () => {
    setActiveStep(0)
    setAnswers([])
  }

  return (
    <GenericModal
      theme={GenericModalTheme.brown}
      title="Take Challenge"
      onClose={onClose}
    >
      <Box display="flex" justifyContent="center" my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {activeStep >= questions.length ? (
              <>
                {score >= passingGrade ? (
                  <img
                    className={classes.img}
                    src={`/badges/${badge}.png`}
                    alt="Passed Image"
                  />
                ) : (
                  <img
                    className={classes.img}
                    src="/full-logo.png"
                    alt="Failed Image"
                  />
                )}
              </>
            ) : (
              <img
                className={classes.img}
                src={`/quizzes/${
                  type === TrailType.DiscoveryTrail
                    ? 'DT'
                    : type === TrailType.LegacyTrail
                    ? 'LT'
                    : 'BM'
                } - ${activeStep + 1}.jpg`}
                alt=""
              />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              className={classes.stepper}
            >
              {questions.map((question, i) => {
                return (
                  <Step key={i}>
                    <StepLabel
                      error={!checkAnswer(i)}
                      StepIconComponent={QuestionStepIcon}
                    ></StepLabel>
                  </Step>
                )
              })}
            </Stepper>

            {activeStep >= questions.length ? (
              <>
                <Typography variant="h5" gutterBottom={true}>
                  Your score is: {score}
                </Typography>
                {score >= passingGrade ? (
                  <Typography>
                    Congratulations on getting a score of {score}! You have
                    passed the {trailName} challenge. You may now get your badge
                    and proceed to the next learning trail.
                  </Typography>
                ) : (
                  <Typography>
                    Uh oh, unfortunately you did not reach a score of{' '}
                    {passingGrade} or higher. You may try and retake the
                    challenge or go back to the Start Trail button to watch the
                    video.
                  </Typography>
                )}
              </>
            ) : (
              <Grid container spacing={2}>
                {/* <Grid item xs={1}>{activeStep + 1}</Grid> */}
                <Grid item>
                  <Box mb={2}>
                    <Typography>{questions[activeStep]?.title}</Typography>
                  </Box>
                  <RadioGroup
                    onChange={e => {
                      const answersCopy = [...answers]
                      answersCopy[activeStep] = e.target.value
                      setAnswers(answersCopy)
                    }}
                  >
                    {questions[activeStep]?.choices.map(choice => {
                      return (
                        <FormControlLabel
                          color="inherit"
                          value={choice}
                          control={<Radio />}
                          label={choice}
                          checked={
                            activeStep > answers.length
                              ? false
                              : answers[activeStep] === choice
                          }
                        />
                      )
                    })}
                  </RadioGroup>
                </Grid>
              </Grid>
            )}

            {activeStep < questions.length ? (
              <Box mt={3} textAlign="right">
                <Button
                  onClick={() => {
                    setActiveStep(activeStep + 1)
                  }}
                  color="secondary"
                  disabled={
                    answers.length === 0 || answers.length === activeStep
                  }
                >
                  <ArrowForwardIcon />
                </Button>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" mt={4}>
                {score >= passingGrade ? (
                  <>
                    <Box mr={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onComplete(true)}
                      >
                        Get your badge!
                      </Button>
                    </Box>
                    <Button
                      variant="contained"
                      onClick={() => onComplete(false)}
                    >
                      Maybe later
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" onClick={reset}>
                    Take the challenge again
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </GenericModal>
  )
}

const useQuestionStepIconStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: 10,
      height: 10,
      borderRadius: 5,
      textAlign: 'center',
      color: 'white',
      fontSize: 0,
      lineHeight: '26px',
      [theme.breakpoints.up('md')]: {
        width: 26,
        height: 26,
        borderRadius: 13,
        fontSize: '0.9rem'
      }
    },
    active: {
      backgroundColor: green[800]
    },
    unanswered: {
      backgroundColor: grey[400]
    },
    correct: {
      backgroundColor: green[400]
    },
    incorrect: {
      backgroundColor: red[400]
    }
  })
)

const QuestionStepIcon = (props: StepIconProps) => {
  const classes = useQuestionStepIconStyles()
  const { active, completed, error, icon } = props

  return (
    <div
      className={
        `${classes.root} ` +
        (completed
          ? error
            ? classes.incorrect
            : classes.correct
          : active
          ? classes.active
          : classes.unanswered)
      }
    >
      {completed ? (
        <>
          {error ? (
            <WrongIcon fontSize="inherit" />
          ) : (
            <CheckIcon fontSize="inherit" />
          )}
        </>
      ) : (
        <span>{String(icon)}</span>
      )}
    </div>
  )
}

export default TakeChallenge
