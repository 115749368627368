import React from 'react'
import {
  Container,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh'
    }
  })
)

const NotFound = () => {
  const styles = useStyles()

  return (
    <FrontsiteLayout>
      <Container className={styles.content}>
        <Typography variant="h4" component="h2">
          Uh oh, page not found
        </Typography>
        <p>
          <Link to="/">Go back to homepage</Link>
        </p>
      </Container>
    </FrontsiteLayout>
  )
}

export default NotFound
