import React from 'react'
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  createStyles,
  IconButton,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  makeStyles
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      opacity: 0.975
    },
    bodyGreen: {
      backgroundColor: '#465133',
      color: '#EEEEEE'
    },
    bodyBrown: {
      backgroundColor: '#5a5434',
      color: '#EEEEEE'
    },
    bodyBlack: {
      backgroundColor: '#46443d',
      color: '#EEEEEE'
    }
  })
)

export enum GenericModalTheme {
  green,
  brown,
  black
}

interface GenericModalProps {
  theme?: GenericModalTheme
  title: string
  onClose: () => void
}

const GenericModal: React.FC<GenericModalProps> = ({
  theme = GenericModalTheme.green,
  title,
  onClose,
  children
}) => {
  const styles = useStyles()

  return (
    <Dialog
      open={true}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
      className={styles.root}
    >
      <DialogTitle id="customized-dialog-title" theme={theme} onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent
        className={
          theme === GenericModalTheme.brown
            ? styles.bodyBrown
            : theme === GenericModalTheme.black
            ? styles.bodyBlack
            : styles.bodyGreen
        }
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#EEEEEE'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    titleGreen: {
      backgroundColor: '#4d5b37'
    },
    titleBrown: {
      backgroundColor: '#675f39'
    },
    titleBlack: {
      backgroundColor: '#4f4c45'
    }
  })

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  theme: GenericModalTheme
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, theme, onClose, ...other } = props
  return (
    <MuiDialogTitle
      disableTypography
      className={
        `${classes.root} ` +
        (theme === GenericModalTheme.brown
          ? classes.titleBrown
          : theme === GenericModalTheme.black
          ? classes.titleBlack
          : classes.titleGreen)
      }
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default GenericModal
