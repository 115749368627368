import React, { useContext } from 'react'
import {
  AppBar,
  Toolbar,
  Button,
  makeStyles,
  createStyles,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Link } from 'react-router-dom'
import { AppContext } from '../states/AppContextProvider'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Info from '@material-ui/icons/Info'
import VpnKeyOutlined from '@material-ui/icons/VpnKeyOutlined'
import Home from '@material-ui/icons/Home'
import logo from '../../assets/masungi-nav-logo.png'
import { UserRole } from '../../models/UserProfile'
import { Student } from '../../models/Student'

const useStyles = makeStyles(theme =>
  createStyles({
    link: {
      color: 'inherit',
      textDecoration: 'none'
    },
    headerLink: {
      lineHeight: 0
    },
    grow: {
      flexGrow: 1
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    menuItem: {
      marginLeft: '2rem'
    }
  })
)

const NavBar = () => {
  const appState = useContext(AppContext).state
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMoreMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Link to="/" className={`${styles.link} ${styles.headerLink}`}>
            <img src={logo} alt="Masungi 360" height={44} />
          </Link>
          <div className={styles.grow} />
          <div className={styles.sectionDesktop}>
            <Link to="/" className={`${styles.link} ${styles.menuItem}`}>
              <Button color="inherit">Home</Button>
            </Link>
            <Link to="/about" className={`${styles.link} ${styles.menuItem}`}>
              <Button color="inherit">About</Button>
            </Link>
            {appState.user && appState.userRole ? (
              <Link
                to={
                  appState.userRole === UserRole.admin
                    ? '/admin'
                    : appState.userRole === UserRole.school
                    ? '/school'
                    : '/profile'
                }
                className={`${styles.link} ${styles.menuItem}`}
              >
                <Button color="inherit">
                  {appState.userRole === UserRole.student &&
                  appState.userProfile ? (
                    <img
                      src={`/avatars/${
                        (appState.userProfile as Student).avatar ?? 'avatar-0'
                      }.png`}
                      alt="avatar"
                      width={36}
                      height={36}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                  &nbsp;
                  {appState.userProfile
                    ? `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                    : appState.user.email}
                </Button>
              </Link>
            ) : (
              <Link to="/login" className={`${styles.link} ${styles.menuItem}`}>
                <Button color="inherit">Login</Button>
              </Link>
            )}
          </div>
          <div className={styles.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls="mobile-menu"
              aria-haspopup="true"
              onClick={handleMoreMenuClick}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="mobile-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMoreMenuClose}
      >
        <Link to="/" className={styles.link}>
          <MenuItem>
            <IconButton aria-label="home" color="inherit">
              <Home />
            </IconButton>
            <p>Home</p>
          </MenuItem>
        </Link>
        <Link to="/about" className={styles.link}>
          <MenuItem>
            <IconButton aria-label="about" color="inherit">
              <Info />
            </IconButton>
            <p>About</p>
          </MenuItem>
        </Link>
        {appState.user && appState.userRole ? (
          <Link
            to={
              appState.userRole === UserRole.admin
                ? '/admin'
                : appState.userRole === UserRole.school
                ? '/school'
                : '/profile'
            }
            className={styles.link}
          >
            <MenuItem>
              <IconButton aria-label="profile" color="inherit">
                <AccountCircle />
              </IconButton>
              <p>
                {appState.userProfile
                  ? `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                  : appState.user.email}
              </p>
            </MenuItem>
          </Link>
        ) : (
          <Link to="/login" className={styles.link}>
            <MenuItem>
              <IconButton aria-label="profile" color="inherit">
                <VpnKeyOutlined />
              </IconButton>
              <p>Login</p>
            </MenuItem>
          </Link>
        )}
      </Menu>
    </>
  )
}

export default NavBar
