import {
  makeStyles,
  createStyles,
  CircularProgress,
  Box
} from '@material-ui/core'
import React from 'react'
import logo from '../assets/masungi-loading-logo.png'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }
  })
)

const Splash = () => {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <Box mb={2}>
        <img src={logo} alt="Masungi 360" height={200} />
      </Box>
      <CircularProgress size={32} style={{ color: '#f2af1a' }} />
    </div>
  )
}

export default Splash
