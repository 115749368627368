import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import { AppContext } from '../states/AppContextProvider'
import { Student } from '../../models/Student'
import { updateAvatar } from '../../services/StudentService'
import { useSnackbar } from 'notistack'
import { getStudentProfile } from '../../services/UserService'
import { ActionType } from '../states/AppReducer'

const useStyles = makeStyles(theme =>
  createStyles({
    avatarWrapper: {
      width: 120,
      padding: 10,
      margin: '0 auto',
      cursor: 'pointer',
      border: '2px solid transparent'
    },
    avatar: {
      width: 100,
      height: 100
    },
    selected: {
      border: '2px solid green',
      borderRadius: 10
    },
    cancelButton: {
      marginRight: '1rem'
    }
  })
)

interface SelectAvatarProps {
  isOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

const SelectAvatar = ({ isOpen, onCancel, onSuccess }: SelectAvatarProps) => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const context = useContext(AppContext)
  const profile = context.state.userProfile as Student
  const [selectedAvatar, setSelectedAvatar] = useState<string | undefined>(
    profile?.avatar
  )
  const [isSaving, setIsSaving] = useState(false)
  const avatars = [
    ['avatar-1', 'avatar-2', 'avatar-3'],
    ['avatar-4', 'avatar-5', 'avatar-6'],
    ['avatar-7', 'avatar-8', 'avatar-9'],
    ['avatar-10', 'avatar-11', 'avatar-12']
  ]

  useEffect(() => {
    if (profile && !selectedAvatar) {
      setSelectedAvatar(profile.avatar)
    }
  }, [profile, selectedAvatar])

  const saveAvatar = async () => {
    if (!selectedAvatar) {
      enqueueSnackbar('You have not selected an avatar!', {
        variant: 'error'
      })
      return
    }

    setIsSaving(true)

    try {
      // Update avatar
      await updateAvatar(profile.id, selectedAvatar)

      // Refetch profile to reflect changes on UI
      const studentProfile = await getStudentProfile(context.state.user!.uid)
      context.dispatch({
        type: ActionType.setUserProfile,
        payload: studentProfile
      })

      setIsSaving(false)
      enqueueSnackbar('You have updated your avatar!', { variant: 'success' })
      onSuccess()
    } catch (err) {
      setIsSaving(false)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Select your avatar</DialogTitle>
      <DialogContent>
        {avatars.map((group, groupNumber) => {
          return (
            <Grid container key={groupNumber}>
              {group.map((avatar, index) => {
                return (
                  <Grid item xs key={groupNumber + index}>
                    <AvatarWrapper
                      avatar={avatar}
                      selected={selectedAvatar === avatar}
                      onSelect={() => {
                        setSelectedAvatar(avatar)
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
        {isSaving && <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Box textAlign="center" p={2} flex={1}>
          <Button
            variant="contained"
            onClick={() => {
              onCancel()
              setSelectedAvatar(profile?.avatar)
            }}
            className={styles.cancelButton}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={saveAvatar}
            disabled={isSaving}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

interface AvatarWrapperProps {
  avatar: string
  onSelect: () => void
  selected: boolean
}

const AvatarWrapper = ({ avatar, selected, onSelect }: AvatarWrapperProps) => {
  const styles = useStyles()

  return (
    <div
      className={
        `${styles.avatarWrapper} ` + (selected ? `${styles.selected}` : ``)
      }
      onClick={onSelect}
    >
      <img
        src={`/avatars/${avatar}.png`}
        alt={avatar}
        className={styles.avatar}
      />
    </div>
  )
}

export default SelectAvatar
