import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Box,
  createStyles,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core'
import { School } from '../../models/School'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import {
  addSchool,
  deleteSchool,
  updateSchool
} from '../../services/AdminService'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import { useConfirm } from 'material-ui-confirm'
import { Operation } from '../../config/enums'
import { validateEmail } from '../../services/UserService'
import { EMAIL_REGEX } from '../../config/constants'

const useStyles = makeStyles(theme =>
  createStyles({
    field: {
      width: '100%'
    },
    cancelBtn: {
      marginRight: '1rem'
    },
    deleteBtn: {
      color: 'darkred'
    }
  })
)

interface SchoolFormProps {
  school?: School
  isOpen: boolean
  onCancel: () => void
  onSuccess: (op: Operation) => void
}

interface Values {
  name: string
  location: string
  capacity: number | ''
  remarks: string
  adminFirstName: string
  adminLastName: string
  adminEmail: string
  adminPhonenumber: string
  adminDesignation: string
}

const SchoolForm = ({
  school,
  isOpen,
  onCancel,
  onSuccess
}: SchoolFormProps) => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()

  const initialValues: Values = school
    ? {
        name: school.name,
        location: school.location,
        capacity: school.capacity,
        remarks: school.remarks,
        adminFirstName: school.admin.firstName,
        adminLastName: school.admin.lastName,
        adminEmail: school.admin.email,
        adminPhonenumber: school.admin.phonenumber,
        adminDesignation: school.admin.designation
      }
    : {
        name: '',
        location: '',
        capacity: '',
        remarks: '',
        adminFirstName: '',
        adminLastName: '',
        adminEmail: '',
        adminPhonenumber: '',
        adminDesignation: ''
      }

  const validate = (values: Values) => {
    const errors: any = {}
    if (!values.name) {
      errors.name = 'Required'
    }
    if (!values.location) {
      errors.location = 'Required'
    }
    if (!values.capacity) {
      errors.capacity = 'Required'
    } else if (isNaN(values.capacity)) {
      errors.capacity = 'Please provide a numeric value'
    }
    if (!values.adminFirstName) {
      errors.adminFirstName = 'Required'
    }
    if (!values.adminLastName) {
      errors.adminLastName = 'Required'
    }
    if (!values.adminEmail) {
      errors.adminEmail = 'Required'
    } else if (!EMAIL_REGEX.test(values.adminEmail)) {
      errors.adminEmail = 'Invalid email address'
    }
    if (!values.adminPhonenumber) {
      errors.adminPhonenumber = 'Required'
    }
    if (!values.adminDesignation) {
      errors.adminDesignation = 'Required'
    }
    return errors
  }

  const handleCreateSchool = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    setSubmitting(true)
    try {
      const exists = await validateEmail(values.adminEmail)
      if (exists) {
        throw new Error(
          'The email address is already in use by another account.'
        )
      }

      const capacity =
        typeof values.capacity === 'number'
          ? (values.capacity as number)
          : parseInt(values.capacity as string)

      await addSchool(values.name, values.location, capacity, values.remarks, {
        firstName: values.adminFirstName,
        lastName: values.adminLastName,
        email: values.adminEmail,
        phonenumber: values.adminPhonenumber,
        designation: values.adminDesignation
      })

      enqueueSnackbar('School successfully created!', { variant: 'success' })
      onSuccess(Operation.create)
    } catch (err) {
      setSubmitting(false)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  const handleUpdateSchool = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    setSubmitting(true)
    try {
      const capacity =
        typeof values.capacity === 'number'
          ? (values.capacity as number)
          : parseInt(values.capacity as string)

      await updateSchool(
        school!.id,
        values.name,
        values.location,
        capacity,
        values.remarks
      )
      enqueueSnackbar('School successfully updated!', { variant: 'success' })
      onSuccess(Operation.update)
    } catch (err) {
      setSubmitting(false)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  const handleDelete = async (setSubmitting: (submitting: boolean) => void) => {
    if (!school) return

    try {
      await confirm({
        description: `Are you sure you want to delete ${school.name}? This action is permanent.`
      })

      setSubmitting(true)
      await deleteSchool(school.id)
      enqueueSnackbar('School successfully deleted!', { variant: 'success' })
      onSuccess(Operation.delete)
    } catch (err) {
      // user cancelled deletion
      if (err) {
        setSubmitting(false)
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      }
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {school ? 'Update' : 'Create'} school
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validate={values => {
          return validate(values)
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (school) {
            handleUpdateSchool(values, setSubmitting)
          } else {
            handleCreateSchool(values, setSubmitting)
          }
        }}
      >
        {({ submitForm, isSubmitting, setSubmitting }) => (
          <Form>
            <DialogContent>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="name"
                  type="text"
                  label="Name"
                  className={styles.field}
                />
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="location"
                  type="text"
                  label="Location"
                  className={styles.field}
                />
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="capacity"
                  type="text"
                  label="Capacity"
                  className={styles.field}
                />
              </Box>
              <Box mb={6}>
                <Field
                  component={TextField}
                  name="remarks"
                  type="text"
                  label="Remarks"
                  className={styles.field}
                  multiline
                  rows={3}
                />
              </Box>

              <Box mb={1}>
                <Typography>School Administrator</Typography>
              </Box>
              <Box mb={1}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="adminFirstName"
                      type="text"
                      label="First Name"
                      className={styles.field}
                      disabled={school !== undefined}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="adminLastName"
                      type="text"
                      label="Last Name"
                      className={styles.field}
                      disabled={school !== undefined}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="adminEmail"
                  type="email"
                  label="Email"
                  className={styles.field}
                  disabled={school !== undefined}
                />
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="adminPhonenumber"
                  type="phonenumber"
                  label="Phonenumber"
                  className={styles.field}
                  disabled={school !== undefined}
                />
              </Box>
              <Box mb={6}>
                <Field
                  component={TextField}
                  name="adminDesignation"
                  type="text"
                  label="Designation"
                  className={styles.field}
                  disabled={school !== undefined}
                />
              </Box>
              {isSubmitting && <LinearProgress />}
            </DialogContent>

            <DialogActions>
              <Box p={2} flex={1} textAlign="center">
                <Button
                  variant="contained"
                  onClick={() => onSuccess(Operation.cancel)}
                  className={styles.cancelBtn}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  color="primary"
                  autoFocus
                >
                  {school ? 'Update' : 'Create'}
                </Button>
                {school && (
                  <Box mt={2}>
                    <Button
                      startIcon={<DeleteIcon />}
                      className={styles.deleteBtn}
                      onClick={() => handleDelete(setSubmitting)}
                    >
                      Delete
                    </Button>
                  </Box>
                )}
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default SchoolForm
