import { User } from 'firebase'
import { School } from '../../models/School'
import { UserRole } from '../../models/UserProfile'
import { UserProfile } from '../../models/UserProfile'
import { AppState } from './AppContextProvider'

export enum ActionType {
  setUser = 'setUser',
  setUserRole = 'setUserRole',
  setUserProfile = 'setUserProfile',
  setSchool = 'setSchool'
}

export type AppAction = {
  type: ActionType
  payload?: any
}

const reducer = (prevState: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case ActionType.setUser:
      return {
        ...prevState,
        user: action.payload as User,
        isInitializing: action.payload ? true : false
      }

    case ActionType.setUserRole:
      if (!prevState.isInitializing) {
        return {
          ...prevState,
          userRole: action.payload as UserRole
        }
      }
      return {
        ...prevState,
        userRole: action.payload as UserRole,
        isInitializing: prevState.user ? false : true
      }

    case ActionType.setUserProfile:
      return {
        ...prevState,
        userProfile: action.payload as UserProfile
      }

    case ActionType.setSchool:
      return {
        ...prevState,
        school: action.payload as School
      }

    default:
      return prevState
  }
}

export default reducer
