import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router'
import AdminLayout from '../../components/layout/AdminLayout'
import { AppContext } from '../../components/states/AppContextProvider'
import { UserRole } from '../../models/UserProfile'
import Dashboard from './Dashboard'
import Reports from './Reports'
import Students from './Students'

const School = () => {
  const appState = useContext(AppContext).state
  const history = useHistory()

  useEffect(() => {
    if (!history) return

    if (!appState.user) {
      // Redirect to school login page if user is not logged in
      history.replace('/school/login')
    } else if (appState.userRole && appState.userRole !== UserRole.school) {
      // Redirect to home page if not a school admin
      history.replace('/')
    } else if (!appState.user.emailVerified) {
      // Redirect to school resetPassword page if user email is
      // not verified yet (using a temporary password)
      // history.replace('/school/resetPassword')
    }
  }, [appState.user, appState.userRole, history])

  return (
    <AdminLayout role={UserRole.school}>
      <Switch>
        <Route exact path="/school" component={Dashboard} />
        <Route path="/school/students" component={Students} />
        <Route path="/school/reports" component={Reports} />
      </Switch>
    </AdminLayout>
  )
}

export default School
