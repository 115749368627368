import React, { useContext } from 'react'
import clsx from 'clsx'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  capitalize,
  Button
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { useHistory, useLocation } from 'react-router-dom'
import { fbAuth } from '../../services/FirebaseService'
import { UserRole } from '../../models/UserProfile'
import { AppContext } from '../states/AppContextProvider'
import { adminRoutes, schoolRoutes } from '../../config/menus'
import logo from '../../assets/masungi-nav-logo.png'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    grow: {
      flexGrow: 1
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: '#4f5d38',
      color: 'white'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    whiteIcon: {
      color: 'white'
    }
  })
)

interface AdminLayoutProps {
  role: UserRole
  children: React.ReactNode
}

const AdminLayout = ({ role, children }: AdminLayoutProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)
  const history = useHistory()
  const school = useContext(AppContext).state.school
  const { pathname } = useLocation()
  const routes = role === UserRole.admin ? adminRoutes : schoolRoutes

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    fbAuth().signOut()
    history.replace('/')
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        color="inherit"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.grow}>
            {school ? school.name : `${capitalize(role)} Portal`}
          </Typography>
          <Button
            color="inherit"
            aria-label="logout"
            onClick={handleLogout}
            endIcon={<LogoutIcon />}
          >
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <img src={logo} alt="Masungi 360" height={30} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon className={classes.whiteIcon} />
            ) : (
              <ChevronRightIcon className={classes.whiteIcon} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {routes.map(route => (
            <ListItem
              button
              key={route.url}
              onClick={() => history.push(route.url)}
              selected={pathname === route.url}
            >
              <ListItemIcon>{route.icon(classes.whiteIcon)}</ListItemIcon>
              <ListItemText primary={route.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  )
}

export default AdminLayout
