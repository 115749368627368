export enum UserRole {
  student = 'student',
  school = 'school',
  admin = 'admin'
}

export interface UserProfile {
  firstName: string
  lastName: string
  role: UserRole
  schoolId?: string
}
