import { schoolConverter } from '../models/School'
import { fbDB } from './FirebaseService'
import { validateEmail } from './UserService'

export interface RawStudent {
  firstName: string
  lastName: string
  gradeLevel: string
  section: string
  email: string
}

export const getSchoolDetail = async (schoolId: string) => {
  const res = await fbDB()
    .collection('schools')
    .doc(schoolId)
    .withConverter(schoolConverter)
    .get()
  return res.data()
}

export const getStudentsCount = async (schoolId: string) => {
  const school = await getSchoolDetail(schoolId)
  return school?.enrolled ?? 0
}

export const addStudent = async (
  schoolId: string,
  firstName: string,
  lastName: string,
  gradeLevel: string,
  section: string,
  email: string,
  remarks: string
) => {
  const today = new Date()
  const data = {
    schoolId,
    firstName,
    lastName,
    gradeLevel,
    section,
    email,
    remarks,
    ranking: 'Trainee',
    avatar: 'avatar-0',
    score: initialScore(),
    createdAt: today,
    updatedAt: today
  }

  await fbDB().collection('students').add(data)
}

export const updateStudent = async (
  id: string,
  firstName: string,
  lastName: string,
  gradeLevel: string,
  section: string,
  remarks: string
) => {
  const data = {
    firstName,
    lastName,
    gradeLevel,
    section,
    remarks: remarks ?? '',
    updatedAt: new Date()
  }

  await fbDB().collection('students').doc(id).update(data)
}

export const deleteStudent = async (id: string) => {
  await fbDB().collection('students').doc(id).delete()
}

export const importStudents = async (
  schoolId: string,
  students: RawStudent[]
) => {
  // Validate email addresses first
  let hasInvalidEmail = false

  const validatePromises: Promise<any>[] = []
  students.forEach(student => {
    validatePromises.push(validateEmail(student.email))
  })

  const validateRes = await Promise.all(validatePromises)
  validateRes.forEach((exists, i) => {
    console.log(`student[${i}] exists?`, exists)
    if (exists === true) {
      hasInvalidEmail = true
    }
  })

  if (hasInvalidEmail) {
    throw new Error(
      "One of the student's email address already exists. Please correct this first and try again. Nothing is imported at this time."
    )
  }

  /**
   * A more primitive approach to adding users individually
   * We do it like this in able to avoid firebase auth limit when creating the accounts concurrently
   */
  // Create each student
  for (const student of students) {
    await addStudent(
      schoolId,
      student.firstName,
      student.lastName,
      student.gradeLevel,
      student.section,
      student.email,
      ''
    )
  }

  /**
   * Facing issue below if there are two many students
   * Firebase has an internal limit as to how many auth accounts we can create per second
   */
  // const today = new Date()
  // const batch = fbDB().batch()

  // students.forEach(student => {
  //   const doc = {
  //     schoolId,
  //     firstName: student.firstName,
  //     lastName: student.lastName,
  //     gradeLevel: student.gradeLevel,
  //     section: student.section,
  //     email: student.email,
  //     ranking: 'Trainee',
  //     avatar: 'avatar-0',
  //     score: initialScore(),
  //     createdAt: today,
  //     updatedAt: today
  //   }

  //   var ref = fbDB().collection('students').doc()
  //   batch.set(ref, doc)
  // })

  // await batch.commit()
}

const initialScore = () => {
  const trailScore = {
    videoWatched: false,
    quizTaken: false,
    quizScore: 0,
    badgeTaken: false
  }

  const score = {
    discoveryTrailDone: false,
    legacyTrailDone: false,
    beyondMasungiDone: false,
    specialMissionDone: false,
    discoveryTrail: trailScore,
    legacyTrail: trailScore,
    beyondMasungi: trailScore,
    specialMission: {
      letterUploaded: false
    }
  }

  return score
}
