import { fbDB } from './FirebaseService'

export const getSchoolsCount = async () => {
  const res = await fbDB().collection('stats').doc('schools').get()
  return res.exists ? (res.data()?.count as number) : 0
}

export const getStats = async () => {
  const res = await fbDB().collection('stats').get()

  const last30Days = new Date()
  last30Days.setMonth(last30Days.getMonth() - 1)
  const res2 = await fbDB()
    .collection('students')
    .where('lastLoggedIn', '>=', last30Days)
    .get()

  if (res.size < 2) {
    return {
      schoolsCount: 0,
      enrolledStudents: 0,
      activeStudents: res2.size
    }
  }

  const stat0 = res.docs[0].data()
  const stat1 = res.docs[1].data()
  console.log('stat0, stat1:', stat0, stat1)

  if (res.docs[0].id === 'schools') {
    return {
      schoolsCount: stat0.count ? parseInt(stat0.count) : 0,
      enrolledStudents: stat1.enrolled ? parseInt(stat1.enrolled) : 0,
      activeStudents: res2.size
    }
  } else {
    return {
      schoolsCount: stat1.count ? parseInt(stat1.count) : 0,
      enrolledStudents: stat0.enrolled ? parseInt(stat0.enrolled) : 0,
      activeStudents: res2.size
    }
  }
}

export const addSchool = async (
  name: string,
  location: string,
  capacity: number,
  remarks: string,
  admin: {
    firstName: string
    lastName: string
    email: string
    phonenumber: string
    designation: string
  }
) => {
  const today = new Date()
  const data = {
    name,
    location,
    capacity,
    remarks,
    admin,
    enrolled: 0,
    active: 0,
    createdAt: today,
    updatedAt: today
  }

  await fbDB().collection('schools').add(data)
}

export const updateSchool = async (
  id: string,
  name: string,
  location: string,
  capacity: number,
  remarks: string
) => {
  const data = {
    name,
    location,
    capacity,
    remarks,
    updatedAt: new Date()
  }

  await fbDB().collection('schools').doc(id).update(data)
}

export const deleteSchool = async (id: string) => {
  await fbDB().collection('schools').doc(id).delete()
}
