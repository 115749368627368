import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { fbAnalytics } from '../services/FirebaseService'

const Analytics = () => {
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname + location.search
    fbAnalytics().logEvent('page_view', { page_path: path })
  }, [location])

  return null
}

export default Analytics
