import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import XLSX from 'xlsx'
import { useSnackbar } from 'notistack'
import FileIcon from '@material-ui/icons/DescriptionOutlined'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { importStudents, RawStudent } from '../../services/SchoolService'
import { AppContext } from '../states/AppContextProvider'

const NO_ROWS_TO_PREVIEW = 5

const useStyles = makeStyles(theme =>
  createStyles({
    // dropzone: {
    //   flex: 1,
    //   display: 'flex',
    //   flexDirection: 'column',
    //   alignItems: 'center',
    //   padding: 20,
    //   borderWidth: 2,
    //   borderRadius: 2,
    //   borderColor: '#eeeeee',
    //   borderStyle: 'dashed',
    //   backgroundColor: '#fafafa',
    //   color: '#bdbdbd',
    //   outline: 'none',
    //   transition: 'border .24s ease-in-out'
    // }
    preview: {
      padding: '2rem',
      backgroundColor: '#f3f3f3'
    },
    table: {},
    tableHead: {
      fontWeight: 'bold'
    },
    cancelBtn: {
      marginRight: '1rem'
    }
  })
)

interface StudentImportProps {
  isOpen: boolean
  onCancel: () => void
  onSuccess: (importedCount: number) => void
}

const StudentImport = ({ isOpen, onCancel, onSuccess }: StudentImportProps) => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const school = useContext(AppContext).state.school
  const [students, setStudents] = useState<RawStudent[]>([])
  const [isImporting, setIsImporting] = useState(false)
  const [willReachCapacity, setWillReachCapacity] = useState(false)

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.xls, .xlsx'
  })

  useEffect(() => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader()
      const isBinary = !!reader.readAsBinaryString

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const res = reader.result

        console.log('Reading xlsx...')
        const workbook = XLSX.read(res, {
          type: isBinary ? 'binary' : 'array'
        })
        console.log('workbook: ', workbook)
        const sheetName = workbook.SheetNames[0]
        console.log('sheetName: ', sheetName)
        const sheet = workbook.Sheets[sheetName]
        console.log('sheet: ', sheet)

        const json = XLSX.utils.sheet_to_json(sheet, { header: 1 })
        console.log(json)

        // Validate columns
        try {
          const columns = json[0] as string[]
          if (columns.length !== 5) {
            throw new Error('The excel sheet should exactly have 5 columns.')
          }

          if (
            columns[0].toLowerCase() !== 'first name' ||
            columns[1].toLowerCase() !== 'last name' ||
            columns[2].toLowerCase() !== 'grade level' ||
            columns[3].toLowerCase() !== 'section' ||
            columns[4].toLowerCase() !== 'email'
          ) {
            throw new Error("The column names doesn't follow our template.")
          }
        } catch (err) {
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
          return
        }

        const rawStudents: RawStudent[] = []
        for (let i = 1; i < json.length; i++) {
          const row = json[i] as string[]
          if (row.length === 0) {
            continue
          }

          rawStudents.push({
            firstName: `${row[0]}`,
            lastName: `${row[1]}`,
            gradeLevel: `${row[2]}`,
            section: `${row[3]}`,
            email: `${row[4]}`
          })
        }

        console.log('rawStudents:', rawStudents)
        setStudents(rawStudents)

        if (school) {
          if (rawStudents.length + school.enrolled > school.capacity) {
            setWillReachCapacity(true)
          } else {
            setWillReachCapacity(false)
          }
        }
      }

      if (isBinary) {
        reader.readAsBinaryString(file)
      } else {
        reader.readAsArrayBuffer(file)
      }
    })
  }, [acceptedFiles]) // eslint-disable-line

  const handleImport = async () => {
    if (!school?.id) return

    try {
      setIsImporting(true)
      await importStudents(school.id, students)
      enqueueSnackbar(
        `${students.length} students has successfully been imported!`,
        {
          variant: 'success'
        }
      )
      onSuccess(students.length)
      setIsImporting(false)
    } catch (err) {
      setIsImporting(false)
      enqueueSnackbar(err.message, {
        variant: 'error',
        persist: true
      })
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Import students from Excel file
        <Box mt={3}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Button
              variant="contained"
              color="default"
              startIcon={<FileIcon />}
            >
              Select file...
            </Button>
            <Typography variant="body1">
              <ul>
                <li>Accepted extensions are .xls and .xlsx only</li>
                <li>Use 1 sheet only, name can be anything</li>
                <li>
                  Column headers should be in the following order: First Name,
                  Last Name, Grade Level, Section, Email
                </li>
              </ul>
            </Typography>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        {students.length > 0 && (
          <Box className={styles.preview}>
            <Table className={`table ${styles.table}`} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHead}>First Name</TableCell>
                  <TableCell className={styles.tableHead}>Last Name</TableCell>
                  <TableCell className={styles.tableHead}>
                    Grade Level
                  </TableCell>
                  <TableCell className={styles.tableHead}>Section</TableCell>
                  <TableCell className={styles.tableHead}>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students
                  .slice(0, Math.min(NO_ROWS_TO_PREVIEW, students.length))
                  .map(student => {
                    return (
                      <TableRow key={student.email}>
                        <TableCell>{student.firstName}</TableCell>
                        <TableCell>{student.lastName}</TableCell>
                        <TableCell>{student.gradeLevel}</TableCell>
                        <TableCell>{student.section}</TableCell>
                        <TableCell>{student.email}</TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box p={2} flex={1} textAlign="center">
          {students.length > 0 && (
            <Box mb={3}>
              <b>Total students to import: {students.length}</b>
            </Box>
          )}
          {willReachCapacity && (
            <Box my={3} mx="15%">
              <Typography align="center" variant="caption" color="error">
                You are about to reach your school's maximum capacity. Please
                contact our Senior Partnership Officers to upgrade your plan.
                <br />
                Capacity: {school?.capacity ?? 0} | Currently enrolled:{' '}
                {school?.enrolled ?? 0}
              </Typography>
            </Box>
          )}
          {isImporting && (
            <>
              <Box mb={3}>
                <LinearProgress />
              </Box>
              <Box mb={3} px="20%">
                <Typography
                  align="center"
                  variant="caption"
                  color="textSecondary"
                >
                  This might take a while especially when importing a large
                  number of students. Please be patient and wait for the process
                  to complete before closing this window.
                </Typography>
              </Box>
            </>
          )}
          <Button
            variant="contained"
            onClick={() => {
              setStudents([])
              setWillReachCapacity(false)
              onCancel()
              setIsImporting(false)
            }}
            className={styles.cancelBtn}
            disabled={isImporting}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={students.length === 0 || isImporting || willReachCapacity}
            startIcon={<CheckIcon />}
            onClick={handleImport}
          >
            Import students
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default StudentImport
