import React from 'react'
import NavBar from './NavBar'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    children: {
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        marginTop: 64
      }
    }
  })
)

const FrontsiteLayout: React.FC = ({ children }) => {
  const styles = useStyles()

  return (
    <div>
      <NavBar />
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default FrontsiteLayout
