import React, { createContext, useEffect, useReducer, useState } from 'react'
import { User as FbUser } from 'firebase'
import reducer, { ActionType } from './AppReducer'
import { fbAuth } from '../../services/FirebaseService'
import { UserRole } from '../../models/UserProfile'
import { getStudentProfile, getUserProfile } from '../../services/UserService'
import { UserProfile } from '../../models/UserProfile'
import { getSchoolDetail } from '../../services/SchoolService'
import { School } from '../../models/School'
import { Student } from '../../models/Student'
import { updateLastLogin } from '../../services/StudentService'

export type AppState = {
  isInitializing: boolean
  user: FbUser | null
  userRole?: UserRole
  userProfile?: UserProfile | Student
  school?: School
}

const initialState = {
  isInitializing: true,
  user: fbAuth().currentUser
}

export const AppContext = createContext<{
  state: AppState
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null
})

const AppContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [lastLoginUpdate, setLastLoginUpdate] = useState(() => {
    const lastLoggedInRaw = localStorage.getItem('m360::lastLoggedIn')
    if (lastLoggedInRaw) {
      return new Date(lastLoggedInRaw)
    } else {
      return new Date('2000-01-01')
    }
  })

  // Watch firebase user authentication changes
  useEffect(() => {
    return fbAuth().onAuthStateChanged(user => {
      if (user) {
        dispatch({ type: ActionType.setUser, payload: user })
      } else {
        dispatch({ type: ActionType.setUser, payload: null })
      }
    })
  }, [])

  // Fetch user role from custom claims right after login
  useEffect(() => {
    if (!state.user) {
      dispatch({ type: ActionType.setUserRole, payload: undefined })
      return
    }

    state.user.getIdTokenResult().then(res => {
      let role = UserRole.student
      if (res.claims.isAdmin) {
        role = UserRole.admin
      } else if (res.claims.isSchool) {
        role = UserRole.school
      }
      dispatch({ type: ActionType.setUserRole, payload: role })
    })
  }, [state.user])

  // Fetch user profile from firestore after role is known
  useEffect(() => {
    const getProfile = async () => {
      if (!state.user || !state.userRole) return

      if (state.userRole === UserRole.student) {
        const profile = await getStudentProfile(state.user?.uid)
        if (profile) {
          dispatch({ type: ActionType.setUserProfile, payload: profile })

          // Update student last login date
          const elapsed = new Date().getTime() - lastLoginUpdate.getTime()
          if (elapsed > 60 * 5 * 1000) {
            // 5 mins ago
            await updateLastLogin(profile.id)
            setLastLoginUpdate(new Date())
            localStorage.setItem('m360::lastLoggedIn', new Date().toUTCString())
          }
        }
      } else {
        const profile = await getUserProfile(state.user?.uid)
        if (profile) {
          dispatch({ type: ActionType.setUserProfile, payload: profile })
        }
      }
    }

    getProfile().then(() => {})
  }, [state.user, state.userRole]) //eslint-disable-line

  // Fetch school info from firestore after userProfile is known
  useEffect(() => {
    if (!state.userProfile || !state.userProfile.schoolId) return

    getSchoolDetail(state.userProfile.schoolId).then(school => {
      if (school) {
        dispatch({ type: ActionType.setSchool, payload: school })
      }
    })
  }, [state.userProfile])

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
