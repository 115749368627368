import React, { useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import Dropzone from 'react-dropzone'
import { fbStorage } from '../../services/FirebaseService'
import { Alert } from '@material-ui/lab'
import classes from '*.module.css'

const useStyles = makeStyles(theme =>
  createStyles({
    dropzone: {
      padding: '1rem',
      backgroundColor: '#f1f1f1',
      textAlign: 'center'
    }
  })
)

interface UploadLetterProps {
  isOpen: boolean
  onCancel: () => void
  showAlert: boolean
  onUploadCompleted: (downloadUrl: string) => void
}

const UploadLetter = ({
  isOpen,
  onCancel,
  showAlert,
  onUploadCompleted
}: UploadLetterProps) => {
  const classes = useStyles()
  const [isUploading, setIsUploading] = useState(false)

  const handleOnDrop = async (files: File[]) => {
    setIsUploading(true)

    const file = files[0]
    const fileUrl = URL.createObjectURL(file)
    const blob = await (await fetch(fileUrl)).blob()

    const fileNameParts = file.name.split('.')
    const childName = `letters/${fileNameParts[0]}_${Date.now()}.${
      fileNameParts[1]
    }`
    console.log('childName:', childName)
    const snapshot = await fbStorage.ref().child(childName).put(blob)

    const downloadUrl = await snapshot.ref.getDownloadURL()
    console.log('downloadUrl:', downloadUrl)
    onUploadCompleted(downloadUrl)
  }

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Letter</DialogTitle>
      <DialogContent>
        {showAlert && (
          <Box mb={3}>
            <Alert severity="warning">
              You have already uploaded a letter. Re-uploading a new letter will
              replace the previously uploaded letter.
            </Alert>
          </Box>
        )}
        <Dropzone
          accept={['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx']}
          multiple={false}
          onDrop={handleOnDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <section className={classes.dropzone}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>
                  Drag and drop your letter here, or click to select your letter
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </DialogContent>
      <DialogActions>
        <Box p={2} flex={1} textAlign="center">
          {isUploading && (
            <Box mb={3}>
              <LinearProgress />
            </Box>
          )}
          <Button
            variant="contained"
            onClick={() => {
              onCancel()
            }}
            // className={styles.cancelBtn}
            disabled={isUploading}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default UploadLetter
