import { fbDB, fbFuncs } from './FirebaseService'
import { UserProfile } from '../models/UserProfile'
import { studentConverter } from '../models/Student'

export const getUserProfile = async (userId: string) => {
  const res = await fbDB().collection('userProfiles').doc(userId).get()
  return res.exists ? (res.data() as UserProfile) : undefined
}

export const getStudentProfile = async (userId: string) => {
  const res = await fbDB()
    .collection('students')
    .where('userId', '==', userId)
    .withConverter(studentConverter)
    .limit(1)
    .get()

  return res.size > 0 ? res.docs[0].data() : undefined
}

export const onResetPassword = async (userId: string) => {
  const func = fbFuncs.httpsCallable('onResetPassword')
  await func({ userId })
}

export const validateEmail = async (email: string) => {
  const func = fbFuncs.httpsCallable('validateEmail')
  const res = await func({ email })
  console.log('validateEmail response: ', res.data)
  return res.data.exists as boolean
}
