import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  createStyles,
  lighten,
  makeStyles,
  Theme
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import EditIcon from '@material-ui/icons/Edit'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Grid,
  Popover,
  TextField
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { School } from '../../models/School'
import { getSchoolsCount } from '../../services/AdminService'
import SchoolForm from '../../components/admin/SchoolForm'
import { SchoolRepo } from '../../repos/SchoolRepo'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Operation } from '../../config/enums'

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof School
  ) => void
  order: SortOrder
  orderBy: string
}

interface EnhancedTableToolbarProps {
  onFiltersChanged: (filters: Filters) => void
}

interface HeadCell {
  id: keyof School
  label: string
  numeric: boolean
}

interface Filters {
  name?: string
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, label: 'Name' },
  { id: 'location', numeric: false, label: 'Location' },
  { id: 'capacity', numeric: true, label: 'Capacity' },
  { id: 'enrolled', numeric: true, label: 'Students Enrolled' },
  { id: 'active', numeric: true, label: 'Active Accounts' }
]

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof School) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">
          <b>Action</b>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: '1 1 100%'
    }
  })
)

const EnhancedTableToolbar = ({
  onFiltersChanged
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopper'
  })
  const [filters, setFilters] = useState<Filters>({})
  const [filtersApplied, setFiltersApplied] = useState(false)

  const applyFilters = () => {
    onFiltersChanged(filters)
    popupState.setOpen(false)
    setFiltersApplied(true)
  }

  const clearFilters = () => {
    onFiltersChanged({})
    setFilters({})
    popupState.setOpen(false)
    setFiltersApplied(false)
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Schools
      </Typography>
      <Tooltip title="Filter list">
        <Badge color="primary" invisible={!filtersApplied} overlap="circle">
          <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
            <FilterListIcon />
          </IconButton>
        </Badge>
      </Tooltip>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter by name</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter school name"
                variant="outlined"
                value={filters.name ?? ''}
                onChange={e =>
                  setFilters({
                    name: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Box p={1} display="flex" justifyContent="space-between">
            <Button onClick={clearFilters}>Clear</Button>
            <Button color="primary" onClick={applyFilters}>
              Apply
            </Button>
          </Box>
        </Paper>
      </Popover>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
)

const Schools = () => {
  const classes = useStyles()
  const [order, setOrder] = useState<SortOrder>('asc')
  const [orderBy, setOrderBy] = useState<keyof School>('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rows, setRows] = useState<School[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState<School | undefined>(
    undefined
  )
  const dense = true // set to true if screen is small
  const [pagingCursor, setPagingCursor] = useState<PagingCursor>({
    beforeId: undefined,
    afterId: undefined
  })
  const [schoolRepo] = useState(new SchoolRepo())
  const [isFiltering, setIsFiltering] = useState(false)

  const emptyRows = () => {
    let maxEmptyRows = rowsPerPage > 10 ? 10 : rowsPerPage
    return (
      maxEmptyRows - Math.min(maxEmptyRows, totalRowCount - page * maxEmptyRows)
    )
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof School
  ) => {
    // Don't sort when on filter mode
    if (isFiltering) return

    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setPagingCursor({
      beforeId: undefined,
      afterId: undefined
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)

    if (newPage > page) {
      // next page
      setPagingCursor({
        beforeId: undefined,
        afterId: rows[rows.length - 1].id
      })
    } else {
      // prev page
      setPagingCursor({
        beforeId: rows[0].id,
        afterId: undefined
      })
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const hideForm = () => {
    setShowForm(false)
    setSelectedSchool(undefined)
  }

  const fetchRows = async (filters?: Filters) => {
    if (!filters) {
      setIsFiltering(false)
    } else {
      const keys = Object.keys(filters)
      setIsFiltering(keys.length > 0)

      setOrder('asc')
      if (filters.name) {
        setOrderBy('name')
      }
    }

    const schools = await schoolRepo.getSchools(
      orderBy,
      order,
      rowsPerPage,
      undefined,
      undefined,
      filters?.name
    )
    setRows(schools)
  }

  useEffect(() => {
    if (!schoolRepo) return
    if (isFiltering) return

    schoolRepo
      .getSchools(
        orderBy,
        order,
        rowsPerPage,
        pagingCursor.beforeId,
        pagingCursor.afterId
      )
      .then(schools => {
        setRows(schools)
      })
  }, [schoolRepo, orderBy, order, rowsPerPage, pagingCursor]) // eslint-disable-line

  useEffect(() => {
    getSchoolsCount().then(count => {
      setTotalRowCount(count)
    })
  }, [])

  return (
    <div className={classes.root}>
      <SchoolForm
        school={selectedSchool}
        isOpen={showForm}
        onCancel={hideForm}
        onSuccess={op => {
          fetchRows()
          hideForm()

          if (op === Operation.create) {
            setTotalRowCount(totalRowCount + 1)
          } else if (op === Operation.delete) {
            setTotalRowCount(totalRowCount - 1)
          }
        }}
      />

      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5">Schools Management</Typography>
          </Grid>
          <Grid item container xs={12} sm={4} justify="flex-end">
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
            >
              New school
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          onFiltersChanged={filters => {
            fetchRows(filters)
          }}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell align="right">{row.capacity}</TableCell>
                    <TableCell align="right">{row.enrolled}</TableCell>
                    <TableCell align="right">{row.active}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedSchool(row)
                          setShowForm(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows() > 0 && (
                <TableRow style={{ height: (dense ? 44 : 53) * emptyRows() }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default Schools
