import {
  makeStyles,
  createStyles,
  Typography,
  Box,
  LinearProgress,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { EMAIL_REGEX } from '../../config/constants'
import { UserRole } from '../../models/UserProfile'
import { fbAuth } from '../../services/FirebaseService'

interface Values {
  email: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: 'center'
    },
    field: {
      width: '100%'
    }
  })
)

interface FirebaseForgotPasswordProps {
  role: UserRole
}

const FirebaseForgotPassword = ({ role }: FirebaseForgotPasswordProps) => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const validate = (values: Values) => {
    console.log('validating...', values)
    const errors: Partial<Values> = {}
    if (!values.email) {
      errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  const resetPassword = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    try {
      await fbAuth().sendPasswordResetEmail(values.email)
      enqueueSnackbar(
        'A password reset email has been sent to your specified email address.',
        {
          variant: 'success',
          persist: true
        }
      )

      if (role === UserRole.school) {
        history.replace('/school')
      } else if (role === UserRole.admin) {
        history.replace('/admin')
      } else {
        history.replace('/login')
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
      setSubmitting(false)
    }
  }

  return (
    <div className={styles.root}>
      <Typography variant="h6" component="h2">
        Forgot Password
      </Typography>

      <Formik
        initialValues={{
          email: ''
        }}
        validate={values => {
          return validate(values)
        }}
        onSubmit={(values, { setSubmitting }) => {
          resetPassword(values, setSubmitting)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mb={6}>
              <Field
                component={TextField}
                name="email"
                type="email"
                label="Email"
                className={styles.field}
              />
            </Box>
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
              fullWidth={true}
              size="large"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FirebaseForgotPassword
