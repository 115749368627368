import {
  Container,
  Card,
  CardContent,
  makeStyles,
  createStyles
} from '@material-ui/core'
import React from 'react'
import FirebaseForgotPassword from '../components/auth/FirebaseForgotPassword'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import { UserRole } from '../models/UserProfile'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      backgroundImage: 'url("login-bg.jpg")',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '100vw',
      height: 'calc(100vh - 64px)',
      display: 'flex',
      alignItems: 'center'
    }
  })
)

const ForgotPassword = () => {
  const styles = useStyles()

  return (
    <FrontsiteLayout>
      <div className={styles.wrapper}>
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <FirebaseForgotPassword role={UserRole.student} />
            </CardContent>
          </Card>
        </Container>
      </div>
    </FrontsiteLayout>
  )
}

export default ForgotPassword
