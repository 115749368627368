import React from 'react'
import FirebaseLogin from '../../components/auth/FirebaseLogin'
import { UserRole } from '../../models/UserProfile'
import AuthLayout from '../../components/layout/AuthLayout'

const Login = () => {
  return (
    <AuthLayout>
      <FirebaseLogin role={UserRole.admin} title="Admin Login" />
    </AuthLayout>
  )
}

export default Login
