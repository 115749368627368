import React, { useContext, useEffect, useState } from 'react'
import GenericModal, { GenericModalTheme } from '../common/GenericModal'
import { Box, Button, Typography } from '@material-ui/core'
import { AppContext } from '../states/AppContextProvider'
import { getStudentProfile } from '../../services/UserService'
import { ActionType } from '../states/AppReducer'
import {
  badgeForTrailType,
  startTrailDone
} from '../../services/StudentService'
import { Student } from '../../models/Student'
import { TrailType } from '../../config/enums'
import PanoramicVideoPlayer from '../common/PanoramicVideoPlayer'

interface StartTrailProps {
  type: TrailType
  onClose: () => void
  onComplete: (doNextStep: boolean) => void
}

const StartTrail = ({ type, onClose, onComplete }: StartTrailProps) => {
  const context = useContext(AppContext)
  const [doneWatching, setDoneWatching] = useState(false)

  useEffect(() => {
    if (!doneWatching) return

    const saveScore = async () => {
      const profile = context.state.userProfile as Student
      if (!profile) return

      // Update student score
      await startTrailDone(profile.id, type)

      // Refetch profile to reflect changes on UI
      const studentProfile = await getStudentProfile(context.state.user!.uid)
      context.dispatch({
        type: ActionType.setUserProfile,
        payload: studentProfile
      })
    }

    saveScore().then()
  }, [doneWatching])

  return (
    <GenericModal
      theme={GenericModalTheme.green}
      title="Start Trail"
      onClose={onClose}
    >
      <Box display="flex" justifyContent="center" my={3}>
        {doneWatching ? (
          <Box mx={10} textAlign="center">
            <Typography variant="h5" gutterBottom={true}>
              Congratulations on finishing the {type} virtual learning trail!
            </Typography>
            <Typography>
              You are now one step closer to becoming an honorary Masungi
              Georeserve {badgeForTrailType(type)}. You may now proceed to the
              quiz page by clicking the TAKE THE CHALLENGE button below.
            </Typography>
            <Box display="flex" justifyContent="center" mt={4}>
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onComplete(true)}
                >
                  Take the challenge
                </Button>
              </Box>
              <Button variant="contained" onClick={() => onComplete(false)}>
                Maybe later
              </Button>
            </Box>
          </Box>
        ) : (
          <PanoramicVideoPlayer
            type={type}
            onVideoEnded={() => setDoneWatching(true)}
          />
        )}
      </Box>
    </GenericModal>
  )
}

export default StartTrail
