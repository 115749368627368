import React, { createRef, useContext } from 'react'
import {
  Button,
  createMuiTheme,
  CssBaseline,
  ThemeProvider
} from '@material-ui/core'
import AppContextProvider, {
  AppContext
} from './components/states/AppContextProvider'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Admin from './pages/admin/Admin'
import AdminLogin from './pages/admin/Login'
import Home from './pages/Home'
import Login from './pages/Login'
import School from './pages/school/School'
import SchoolLogin from './pages/school/Login'
import SchoolResetPassword from './pages/school/ResetPassword'
import NotFound from './pages/NotFound'
import Splash from './pages/Splash'
import Profile from './pages/Profile'
import About from './pages/About'
import { SnackbarProvider } from 'notistack'
import { ConfirmProvider } from 'material-ui-confirm'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Analytics from './components/Analytics'
import Badge from './pages/Badge'
import SpecialMission from './pages/SpecialMission'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

const App = () => {
  const isInitializing = useContext(AppContext).state.isInitializing

  return (
    <>
      {isInitializing ? (
        <Splash />
      ) : (
        <BrowserRouter>
          <Analytics />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/specialMission" component={SpecialMission} />
            <Route path="/about" component={About} />
            <Route path="/login" component={Login} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/profile" component={Profile} />
            <Route path="/badges/:badgeName/:email" component={Badge} />

            <Route exact path="/school/login" component={SchoolLogin} />
            <Route
              exact
              path="/school/resetPassword"
              component={SchoolResetPassword}
            />
            <Route path="/school" component={School} />

            <Route exact path="/admin/login" component={AdminLogin} />
            <Route path="/admin" component={Admin} />

            <Route path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      )}
    </>
  )
}

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#4f5c37'
    },
    secondary: {
      main: '#b59a33'
    },
    background: {
      default: '#eaeee7'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'PierSans'
  }
})

const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h3: {
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '2.5rem'
        }
      }
    }
  }
}

const Wrapper = () => {
  const notistackRef = createRef<SnackbarProvider>()
  const dismissSnackbar = (key: React.ReactText) => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContextProvider>
        <SnackbarProvider
          maxSnack={1}
          ref={notistackRef}
          action={key => (
            <Button onClick={() => dismissSnackbar(key)} color="inherit">
              Dismiss
            </Button>
          )}
        >
          <ConfirmProvider
            defaultOptions={{
              title: 'Confirmation',
              confirmationButtonProps: { autoFocus: true },
              dialogProps: {
                open: false,
                maxWidth: 'xs'
              }
            }}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ConfirmProvider>
        </SnackbarProvider>
      </AppContextProvider>
    </ThemeProvider>
  )
}

export default Wrapper
