import { Badges, TrailType } from '../config/enums'
import { Student } from '../models/Student'
import { fbDate, fbDB, fbFuncs } from './FirebaseService'

export const updateLastLogin = async (studentId: string) => {
  const func = fbFuncs.httpsCallable('updateStudentLastLoggedIn')
  try {
    const res = await func({ studentId })
    console.log('updateStudentLastLoggedIn response: ', res.data)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

export const updateAvatar = async (studentId: string, avatar: string) => {
  const data = {
    avatar
  }

  await fbDB().collection('students').doc(studentId).update(data)
}

export const startTrailDone = async (studentId: string, type: TrailType) => {
  const today = fbDate(new Date())
  const data: { [key: string]: any } = {}

  switch (type) {
    case TrailType.LegacyTrail:
      data['score.legacyTrail.videoWatched'] = true
      data['score.legacyTrail.updatedAt'] = today
      break
    case TrailType.BeyondMasungi:
      data['score.beyondMasungi.videoWatched'] = true
      data['score.beyondMasungi.updatedAt'] = today
      break
    case TrailType.DiscoveryTrail:
    default:
      data['score.discoveryTrail.videoWatched'] = true
      data['score.discoveryTrail.updatedAt'] = today
      break
  }

  await fbDB().collection('students').doc(studentId).update(data)
}

export const takeChallengeDone = async (
  studentId: string,
  type: TrailType,
  score: number
) => {
  const today = fbDate(new Date())
  const data: { [key: string]: any } = {}

  switch (type) {
    case TrailType.LegacyTrail:
      data['score.legacyTrail.quizTaken'] = true
      data['score.legacyTrail.quizScore'] = score
      data['score.legacyTrail.updatedAt'] = today
      break
    case TrailType.BeyondMasungi:
      data['score.beyondMasungi.quizTaken'] = true
      data['score.beyondMasungi.quizScore'] = score
      data['score.beyondMasungi.updatedAt'] = today
      break
    case TrailType.DiscoveryTrail:
    default:
      data['score.discoveryTrail.quizTaken'] = true
      data['score.discoveryTrail.quizScore'] = score
      data['score.discoveryTrail.updatedAt'] = today
      break
  }

  await fbDB().collection('students').doc(studentId).update(data)
}

export const getBadgeDone = async (
  studentId: string,
  currentRanking: string,
  type: TrailType
) => {
  const today = fbDate(new Date())
  const data: { [key: string]: any } = {}

  switch (type) {
    case TrailType.SpecialMission:
      data['score.specialMission.badgeTaken'] = true
      data['score.specialMission.updatedAt'] = today
      data['score.specialMissionDone'] = true
      data['ranking'] = newRankAfterGettingBadge(Badges.SA, currentRanking)
      data['keywords.ranking'] = [Badges.SA.toLowerCase()]
      break
    case TrailType.BeyondMasungi:
      data['score.beyondMasungi.badgeTaken'] = true
      data['score.beyondMasungi.updatedAt'] = today
      data['score.beyondMasungiDone'] = true
      data['ranking'] = newRankAfterGettingBadge(Badges.PR, currentRanking)
      data['keywords.ranking'] = [Badges.PR.toLowerCase()]
      break
    case TrailType.LegacyTrail:
      data['score.legacyTrail.badgeTaken'] = true
      data['score.legacyTrail.updatedAt'] = today
      data['score.legacyTrailDone'] = true
      data['ranking'] = newRankAfterGettingBadge(Badges.JPR, currentRanking)
      data['keywords.ranking'] = [Badges.JPR.toLowerCase()]
      break
    case TrailType.DiscoveryTrail:
    default:
      data['score.discoveryTrail.badgeTaken'] = true
      data['score.discoveryTrail.updatedAt'] = today
      data['score.discoveryTrailDone'] = true
      data['ranking'] = newRankAfterGettingBadge(Badges.PRT, currentRanking)
      data['keywords.ranking'] = [Badges.PRT.toLowerCase()]
      break
  }

  console.log('studentId:', studentId)
  console.log('data:', data)

  await fbDB().collection('students').doc(studentId).update(data)
}

export const uploadLetterDone = async (
  studentId: string,
  downloadUrl: string
) => {
  // await fbDB()
  //   .collection('students')
  //   .doc(studentId)
  //   .update({
  //     'score.specialMission.letterUploaded': true,
  //     'score.specialMission.downloadLink': downloadUrl,
  //     'score.specialMission.updatedAt': fbDate(new Date())
  //   })
  const func = fbFuncs.httpsCallable('uploadStudentLetter')
  try {
    const res = await func({ studentId, downloadUrl })
    console.log('uploadStudentLetter response: ', res.data)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getByEmail = async (email: string) => {
  const res = await fbDB()
    .collection('students')
    .where('email', '==', email)
    .limit(1)
    .get()

  return res.docs.length ? (res.docs[0].data() as Student) : undefined
}

export const badgeForTrailType = (type: TrailType) => {
  let badge = Badges.PRT
  if (type === TrailType.LegacyTrail) {
    badge = Badges.JPR
  } else if (type === TrailType.BeyondMasungi) {
    badge = Badges.PR
  } else if (type === TrailType.SpecialMission) {
    badge = Badges.SA
  }

  return badge
}

export const badgeOfficialName = (badge: string) => {
  if (badge === Badges.SA) {
    return `#${badge}`
  }
  return badge
}

/**
 * Ensures that student rank won't be demoted
 */
const newRankAfterGettingBadge = (badge: Badges, currentRanking: string) => {
  console.log('badge, currentRanking:', badge, currentRanking)
  if (
    currentRanking === Badges.SA ||
    currentRanking === badgeOfficialName(Badges.SA)
  ) {
    return badgeOfficialName(Badges.SA)
  } else if (currentRanking === Badges.PR && badge !== Badges.SA) {
    return badgeOfficialName(Badges.PR)
  } else if (
    currentRanking === Badges.JPR &&
    badge !== Badges.SA &&
    badge !== Badges.PR
  ) {
    return badgeOfficialName(Badges.JPR)
  }
  return badgeOfficialName(badge)
}
