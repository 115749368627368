import React, { useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Box,
  createStyles,
  makeStyles,
  Grid
} from '@material-ui/core'
import { Student } from '../../models/Student'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import {
  addStudent,
  deleteStudent,
  updateStudent
} from '../../services/SchoolService'
import { useSnackbar } from 'notistack'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import { useConfirm } from 'material-ui-confirm'
import { Operation } from '../../config/enums'
import { validateEmail } from '../../services/UserService'
import { AppContext } from '../states/AppContextProvider'
import { EMAIL_REGEX } from '../../config/constants'

const useStyles = makeStyles(theme =>
  createStyles({
    field: {
      width: '100%'
    },
    cancelBtn: {
      marginRight: '1rem'
    },
    deleteBtn: {
      color: 'darkred'
    }
  })
)

interface StudentFormProps {
  student?: Student
  isOpen: boolean
  onCancel: () => void
  onSuccess: (op: Operation) => void
}

interface Values {
  firstName: string
  lastName: string
  gradeLevel: string
  section: string
  email: string
  remarks: string
}

const StudentForm = ({
  student,
  isOpen,
  onCancel,
  onSuccess
}: StudentFormProps) => {
  const styles = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const confirm = useConfirm()
  const school = useContext(AppContext).state.school

  const initialValues: Values = student
    ? {
        firstName: student.firstName,
        lastName: student.lastName,
        gradeLevel: student.gradeLevel,
        section: student.section,
        email: student.email,
        remarks: student.remarks
      }
    : {
        firstName: '',
        lastName: '',
        gradeLevel: '',
        section: '',
        email: '',
        remarks: ''
      }

  const validate = (values: Values) => {
    const errors: any = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (!values.lastName) {
      errors.lastName = 'Required'
    }
    if (!values.gradeLevel) {
      errors.gradeLevel = 'Required'
    }
    if (!values.section) {
      errors.section = 'Required'
    }
    if (!values.email) {
      errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    return errors
  }

  const handleCreateStudent = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    setSubmitting(true)
    try {
      // Check if email is already taken
      const exists = await validateEmail(values.email)
      if (exists) {
        throw new Error(
          'The email address is already in use by another account.'
        )
      }

      // Check if capacity is reached
      if (school!.enrolled + 1 > school!.capacity) {
        throw new Error(
          "You have reached your school's maximum capacity. Please contact our Senior " +
            'Partnership Officers to upgrade your plan.'
        )
      }

      await addStudent(
        school!.id,
        values.firstName,
        values.lastName,
        values.gradeLevel,
        values.section,
        values.email,
        values.remarks
      )

      enqueueSnackbar('Student successfully created!', { variant: 'success' })
      onSuccess(Operation.create)
    } catch (err) {
      setSubmitting(false)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  const handleUpdateStudent = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    setSubmitting(true)
    try {
      await updateStudent(
        student!.id,
        values.firstName,
        values.lastName,
        values.gradeLevel,
        values.section,
        values.remarks
      )

      enqueueSnackbar('Student successfully updated!', { variant: 'success' })
      onSuccess(Operation.update)
    } catch (err) {
      setSubmitting(false)
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }
  }

  const handleDelete = async (setSubmitting: (submitting: boolean) => void) => {
    if (!student) return

    try {
      await confirm({
        description: `Are you sure you want to delete ${student.firstName}? This action is permanent.`
      })

      setSubmitting(true)
      await deleteStudent(student.id)
      enqueueSnackbar('Student successfully deleted!', { variant: 'success' })
      onSuccess(Operation.delete)
    } catch (err) {
      // user cancelled deletion
      if (err) {
        setSubmitting(false)
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      }
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {student ? 'Update' : 'Create'} student
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validate={values => {
          return validate(values)
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (student) {
            handleUpdateStudent(values, setSubmitting)
          } else {
            handleCreateStudent(values, setSubmitting)
          }
        }}
      >
        {({ submitForm, isSubmitting, setSubmitting }) => (
          <Form>
            <DialogContent>
              <Box mb={1}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="firstName"
                      type="text"
                      label="First Name"
                      className={styles.field}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      name="lastName"
                      type="text"
                      label="Last Name"
                      className={styles.field}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="gradeLevel"
                  type="text"
                  label="Grade Level"
                  className={styles.field}
                />
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="section"
                  type="text"
                  label="Section"
                  className={styles.field}
                />
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  className={styles.field}
                  disabled={student !== undefined}
                />
              </Box>
              <Box mb={6}>
                <Field
                  component={TextField}
                  name="remarks"
                  type="text"
                  label="Remarks"
                  className={styles.field}
                  multiline
                  rows={3}
                />
              </Box>
              {isSubmitting && <LinearProgress />}
            </DialogContent>
            <DialogActions>
              <Box p={2} flex={1} textAlign="center">
                <Button
                  variant="contained"
                  onClick={() => onSuccess(Operation.cancel)}
                  className={styles.cancelBtn}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={submitForm}
                  color="primary"
                  autoFocus
                >
                  {student ? 'Update' : 'Create'}
                </Button>
                {student && (
                  <Box mt={2}>
                    <Button
                      startIcon={<DeleteIcon />}
                      className={styles.deleteBtn}
                      onClick={() => handleDelete(setSubmitting)}
                    >
                      Delete
                    </Button>
                  </Box>
                )}
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default StudentForm
