import classes from '*.module.css'
import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import UploadLetter from '../components/student/UploadLetter'
import UploadLetterSuccess from '../components/student/UploadLetterSuccess'
import { AppContext } from '../components/states/AppContextProvider'
import GetBadge from '../components/student/GetBadge'
import { TrailType } from '../config/enums'
import { Student } from '../models/Student'
import { uploadLetterDone } from '../services/StudentService'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      minHeight: 'calc(100vh - 64px)'
    },
    sidebar: {
      backgroundImage: 'url("specialmission-bg.png")',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    },
    mainContent: {
      paddingLeft: '15px',
      paddingRight: '15px',
      '& h3': {
        marginBottom: '3rem'
      },
      '& p': {
        marginTop: '1.5rem'
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '3rem',
        paddingRight: '3rem'
      }
    }
  })
)

const SpecialMission = () => {
  const student = useContext(AppContext).state.userProfile as Student
  const [showUploadLetterModal, setShowUploadLetterModal] = useState(false)
  const [
    showUploadLetterSuccessModal,
    setShowUploadLetterSuccessModal
  ] = useState(false)
  const [showGetBadgeModal, setShowGetBadgeModal] = useState(false)
  const [isBadgeAvailable, setShowIsBadgeAvailable] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    if ((student && student.score.specialMission.letterUploaded) ?? false) {
      setShowIsBadgeAvailable(true)
    }
  }, [student])

  return (
    <FrontsiteLayout>
      {showUploadLetterModal && (
        <UploadLetter
          isOpen={showUploadLetterModal}
          onCancel={() => setShowUploadLetterModal(false)}
          showAlert={student?.score.specialMission.letterUploaded ?? false}
          onUploadCompleted={async downloadUrl => {
            await uploadLetterDone(student.id, downloadUrl)
            setShowUploadLetterModal(false)
            setShowIsBadgeAvailable(true)
            setShowUploadLetterSuccessModal(true)
          }}
        />
      )}

      {showUploadLetterSuccessModal && (
        <UploadLetterSuccess
          isOpen={showUploadLetterSuccessModal}
          onDismiss={() => setShowUploadLetterSuccessModal(false)}
        />
      )}

      {showGetBadgeModal && (
        <GetBadge
          onClose={() => setShowGetBadgeModal(false)}
          type={TrailType.SpecialMission}
        />
      )}

      <Grid container className={classes.wrapper}>
        <Grid item xs={12} md={4} className={classes.sidebar}></Grid>
        <Grid item xs={12} md={8} className={classes.mainContent}>
          <Box my={4}>
            <Typography variant="h3" align="center">
              10,000 Letters Campaign for the #SaveMasungi Movement
            </Typography>
            <Typography>
              The 10,000 Letters campaign aims to engage the youth in the
              conservation of Philippine forests and watersheds, specifically
              the Upper Marikina River Basin Protected Landscape (UMRBPL), of
              which the Masungi Geopark Project (MGP) is a part of. By
              conducting continued tree planting and nurturing activities,
              patrolling the area against external threats, and raising
              awareness on the importance of the watershed to local communities,
              and even the metro, we aim to restore around 2, 700 hectares of
              denuded lands surrounding the georeserve. Through the help of our
              team and our supporters, we wish to bring back the area's native
              biodiversity, protect the water supply, and prevent extreme
              flooding in low-lying areas.
            </Typography>
            <Typography>
              In your own words, write a letter addressed to Philippine leaders
              and policy-makers, answering the question "Why should we save
              Philippine forests, specifically, the Upper Marikina watershed and
              Masungi Georeserve?"
            </Typography>
            <Typography>
              Save your letter in pdf format and upload it below to contribute
              to the 10,000 Letters Campaign! We will collate the letters and
              send them to our leaders and other concerned stakeholders.
            </Typography>
            <Typography>
              Once you have uploaded your letter, you may click the Get Badge
              button to claim #SaveMasungi Advocate badge and download your
              certificate signifying that a tree is planted on your behalf!
            </Typography>
            <Box mt={6} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowUploadLetterModal(true)}
              >
                Upload Letter
              </Button>
            </Box>
            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                disabled={!isBadgeAvailable}
                onClick={() => setShowGetBadgeModal(true)}
              >
                Get Badge
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </FrontsiteLayout>
  )
}

export default SpecialMission
