export enum Operation {
  create,
  update,
  delete,
  cancel
}

export enum TrailType {
  DiscoveryTrail = 'Discovery Trail',
  LegacyTrail = 'Legacy Trail',
  BeyondMasungi = 'Beyond Masungi',
  SpecialMission = 'Special Mission'
}

export enum Badges {
  PRT = 'Park Ranger Trainee',
  JPR = 'Junior Park Ranger',
  PR = 'Park Ranger',
  SA = 'SaveMasungi Advocate'
}
