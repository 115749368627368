import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  createStyles,
  lighten,
  makeStyles,
  Theme
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import EditIcon from '@material-ui/icons/Edit'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Grid,
  Popover,
  TextField
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import UploadIcon from '@material-ui/icons/Publish'
import { Student } from '../../models/Student'
import { getStudentsCount } from '../../services/SchoolService'
import StudentForm from '../../components/school/StudentForm'
import { StudentFilters, StudentRepo } from '../../repos/StudentRepo'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Operation } from '../../config/enums'
import { AppContext } from '../../components/states/AppContextProvider'
import StudentImport from '../../components/school/StudentImport'
import { ActionType } from '../../components/states/AppReducer'

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Student
  ) => void
  order: SortOrder
  orderBy: string
}

interface EnhancedTableToolbarProps {
  onFiltersChanged: (filters: StudentFilters) => void
}

interface HeadCell {
  id: keyof Student
  label: string
}

const headCells: HeadCell[] = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'gradeLevel', label: 'Grade Level' },
  { id: 'section', label: 'Section' },
  { id: 'email', label: 'Email' },
  { id: 'ranking', label: 'Ranking' }
]

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Student) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <b>{headCell.label}</b>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right">
          <b>Action</b>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: '1 1 100%'
    }
  })
)

const EnhancedTableToolbar = ({
  onFiltersChanged
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopper'
  })
  const [filters, setFilters] = useState<StudentFilters>({})
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [expanded, setExpanded] = useState<string | undefined>(undefined)

  const applyFilters = () => {
    onFiltersChanged(filters)
    popupState.setOpen(false)
    setFiltersApplied(true)
  }

  const clearFilters = () => {
    onFiltersChanged({})
    setFilters({})
    popupState.setOpen(false)
    setFiltersApplied(false)
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: false
      })}
    >
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Students
      </Typography>
      <Tooltip title="Filter list">
        <Badge color="primary" invisible={!filtersApplied} overlap="circle">
          <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
            <FilterListIcon />
          </IconButton>
        </Badge>
      </Tooltip>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper>
          <Accordion
            defaultExpanded={filters.firstName !== undefined}
            expanded={expanded === 'firstName'}
            onChange={() => setExpanded('firstName')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Filter by first name</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter first name"
                variant="outlined"
                value={filters.firstName ?? ''}
                onChange={e =>
                  setFilters({
                    firstName: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={filters.lastName !== undefined}
            expanded={expanded === 'lastName'}
            onChange={() => setExpanded('lastName')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1b-content"
              id="panel1b-header"
            >
              <Typography>Filter by last name</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter last name"
                variant="outlined"
                value={filters.lastName ?? ''}
                onChange={e =>
                  setFilters({
                    lastName: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={filters.gradeLevel !== undefined}
            expanded={expanded === 'gradeLevel'}
            onChange={() => setExpanded('gradeLevel')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <Typography>Filter by grade level</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter grade level"
                variant="outlined"
                value={filters.gradeLevel ?? ''}
                onChange={e =>
                  setFilters({
                    gradeLevel: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={filters.section !== undefined}
            expanded={expanded === 'section'}
            onChange={() => setExpanded('section')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Filter by section</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter section"
                variant="outlined"
                value={filters.section ?? ''}
                onChange={e =>
                  setFilters({
                    section: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={filters.email !== undefined}
            expanded={expanded === 'email'}
            onChange={() => setExpanded('email')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1e-content"
              id="panel1e-header"
            >
              <Typography>Filter by email</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter email"
                variant="outlined"
                value={filters.email ?? ''}
                onChange={e =>
                  setFilters({
                    email: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            defaultExpanded={filters.ranking !== undefined}
            expanded={expanded === 'ranking'}
            onChange={() => setExpanded('ranking')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1f-content"
              id="panel1f-header"
            >
              <Typography>Filter by ranking</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Enter ranking"
                variant="outlined"
                value={filters.ranking ?? ''}
                onChange={e =>
                  setFilters({
                    ranking: e.currentTarget.value
                  })
                }
              />
            </AccordionDetails>
          </Accordion>
          <Box p={1} display="flex" justifyContent="space-between">
            <Button onClick={clearFilters}>Clear</Button>
            <Button color="primary" onClick={applyFilters}>
              Apply
            </Button>
          </Box>
        </Paper>
      </Popover>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
)

const Students = () => {
  const classes = useStyles()
  const [order, setOrder] = useState<SortOrder>('asc')
  const [orderBy, setOrderBy] = useState<keyof Student>('lastName')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rows, setRows] = useState<Student[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(
    undefined
  )
  const dense = true // set to true if screen is small
  const [pagingCursor, setPagingCursor] = useState<PagingCursor>({
    beforeId: undefined,
    afterId: undefined
  })
  const [studentRepo] = useState(new StudentRepo())
  const [isFiltering, setIsFiltering] = useState(false)
  const context = useContext(AppContext)
  const schoolId = context.state.school?.id
  const [showImport, setShowImport] = useState(false)

  const emptyRows = () => {
    let maxEmptyRows = rowsPerPage > 10 ? 10 : rowsPerPage
    return (
      maxEmptyRows - Math.min(maxEmptyRows, totalRowCount - page * maxEmptyRows)
    )
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Student
  ) => {
    // Don't sort when on filter mode
    if (isFiltering) return

    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    setPagingCursor({
      beforeId: undefined,
      afterId: undefined
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)

    if (newPage > page) {
      // next page
      setPagingCursor({
        beforeId: undefined,
        afterId: rows[rows.length - 1].id
      })
    } else {
      // prev page
      setPagingCursor({
        beforeId: rows[0].id,
        afterId: undefined
      })
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const hideForm = () => {
    setShowForm(false)
    setSelectedStudent(undefined)
  }

  const fetchRows = async (filters?: StudentFilters) => {
    if (!schoolId) return

    if (!filters) {
      setIsFiltering(false)
    } else {
      const keys = Object.keys(filters)
      setIsFiltering(keys.length > 0)

      setOrder('asc')
      if (filters.firstName) {
        setOrderBy('firstName')
      } else if (filters.lastName) {
        setOrderBy('lastName')
      } else if (filters.gradeLevel) {
        setOrderBy('gradeLevel')
      } else if (filters.section) {
        setOrderBy('section')
      } else if (filters.email) {
        setOrderBy('email')
      } else if (filters.ranking) {
        setOrderBy('ranking')
      }
    }

    const students = await studentRepo.getStudents(
      schoolId,
      orderBy,
      order,
      rowsPerPage,
      undefined,
      undefined,
      filters
    )
    setRows(students)
  }

  useEffect(() => {
    if (!schoolId) return
    if (!studentRepo) return
    if (isFiltering) return

    studentRepo
      .getStudents(
        schoolId,
        orderBy,
        order,
        rowsPerPage,
        pagingCursor.beforeId,
        pagingCursor.afterId
      )
      .then(students => {
        setRows(students)
      })
  }, [schoolId, studentRepo, orderBy, order, rowsPerPage, pagingCursor]) // eslint-disable-line

  useEffect(() => {
    if (!schoolId) return

    getStudentsCount(schoolId).then(count => {
      setTotalRowCount(count)
    })
  }, [schoolId])

  return (
    <div className={classes.root}>
      <StudentForm
        student={selectedStudent}
        isOpen={showForm}
        onCancel={hideForm}
        onSuccess={op => {
          fetchRows()
          hideForm()

          if (op === Operation.create) {
            setTotalRowCount(totalRowCount + 1)
          } else if (op === Operation.delete) {
            setTotalRowCount(totalRowCount - 1)
          }

          // Update school enrolled count in app cache
          context.dispatch({
            type: ActionType.setSchool,
            payload: {
              ...context.state.school,
              enrolled: totalRowCount + (op === Operation.create ? 1 : -1)
            }
          })
        }}
      />

      <StudentImport
        isOpen={showImport}
        onCancel={() => setShowImport(false)}
        onSuccess={importedCount => {
          fetchRows()
          setShowImport(false)
          setTotalRowCount(totalRowCount + importedCount)

          // Update school enrolled count in app cache
          context.dispatch({
            type: ActionType.setSchool,
            payload: {
              ...context.state.school,
              enrolled: totalRowCount + importedCount
            }
          })
        }}
      />

      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">Students Management</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} justify="flex-end">
            <Box mr={3}>
              <Button
                startIcon={<UploadIcon />}
                variant="contained"
                color="primary"
                onClick={() => setShowImport(true)}
              >
                Import from file
              </Button>
            </Box>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => setShowForm(true)}
            >
              New Student
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          onFiltersChanged={filters => {
            fetchRows(filters)
          }}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.firstName}
                    </TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.gradeLevel}</TableCell>
                    <TableCell>{row.section}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.ranking}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedStudent(row)
                          setShowForm(true)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows() > 0 && (
                <TableRow style={{ height: (dense ? 44 : 53) * emptyRows() }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default Students
