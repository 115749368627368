import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { UserRole } from '../../models/UserProfile'
import { AppContext } from '../states/AppContextProvider'
import DiscoveryImg from '../../assets/menu-icon-discovery.png'
import LegacyImg from '../../assets/menu-icon-legacy.png'
import BeyondImg from '../../assets/menu-icon-beyond.png'
import SpecialImg from '../../assets/menu-icon-special.png'
import DiscoveryLockedImg from '../../assets/menu-icon-discovery-locked.png'
import LegacyLockedImg from '../../assets/menu-icon-legacy-locked.png'
import BeyondLockedImg from '../../assets/menu-icon-beyond-locked.png'
import SpecialLockedImg from '../../assets/menu-icon-special-locked.png'
import BackImg from '../../assets/menu-icon-back.png'
import StartImg from '../../assets/menu-icon-start.png'
import ChallengeImg from '../../assets/menu-icon-challenge.png'
import ChallengeLockedImg from '../../assets/menu-icon-challenge-locked.png'
import BadgeImg from '../../assets/menu-icon-badge.png'
import BadgeLockedImg from '../../assets/menu-icon-badge-locked.png'
import { Student } from '../../models/Student'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme =>
  createStyles({
    menuWrapper: {
      display: 'flex',
      height: 'calc(100vh - 56px)',
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh - 64px)'
      }
    },
    menuGrid: {
      marginTop: 'auto',
      // marginBottom: '6rem',
      marginBottom: '3rem',
      textAlign: 'center'
    },
    menuButton: {
      paddingBottom: 0,
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        marginLeft: '1rem',
        marginRight: '1rem'
      }
    },
    menuIcon: {
      height: 44,
      width: 44,
      objectFit: 'scale-down',
      [theme.breakpoints.up('md')]: {
        height: 80,
        width: 80
      }
    },
    menuLabel: {
      position: 'relative',
      color: 'white',
      fontSize: '0.7rem',
      marginTop: 0,
      lineHeight: '1.1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      }
    },
    backItem: {
      borderRight: '1px solid lightGray',
      zIndex: 1,
      paddingRight: '0.5rem',
      marginRight: '0.5rem',
      [theme.breakpoints.up('md')]: {
        paddingRight: '2rem',
        marginRight: '2rem'
      }
    }
  })
)

export enum Menu {
  HOME,
  DISCOVERY_TRAIL,
  LEGACY_TRAIL,
  BEYOND_MASUNGI,
  SPECIAL_MISSION
}

export enum Resource {
  DT_MAIN,
  DT_CHALLENGE,
  DT_BADGE,
  LT_MAIN,
  LT_CHALLENGE,
  LT_BADGE,
  BM_MAIN,
  BM_CHALLENGE,
  BM_BADGE,
  SM_MAIN,
  SM_BADGE
}

interface DockMenuProps {
  onMenuChange: (menuName: Menu) => void
  onResourceClicked: (resource: Resource) => void
}

const DockMenu = ({ onMenuChange, onResourceClicked }: DockMenuProps) => {
  const styles = useStyles()
  const [activeMenu, setActiveMenu] = useState(Menu.HOME)
  const appState = useContext(AppContext).state
  const history = useHistory()

  const handleMenuClick = (menu: Menu) => {
    if (menu === Menu.SPECIAL_MISSION) {
      history.push('/specialMission')
      return
    }

    setActiveMenu(menu)
    onMenuChange(menu)
  }

  const hasUnlocked = (resource: Resource) => {
    if (
      !appState.user ||
      appState.userRole !== UserRole.student ||
      !appState.userProfile
    ) {
      // A student is not logged in
      return false
    }

    // "Discovery Trail" is unlocked by default
    if (resource === Resource.DT_MAIN) return true

    const score = (appState.userProfile as Student).score

    switch (resource) {
      case Resource.DT_CHALLENGE:
        return score?.discoveryTrail?.videoWatched ?? false
      case Resource.DT_BADGE:
        return score?.discoveryTrail?.quizTaken ?? false
      case Resource.LT_MAIN:
        return score?.discoveryTrailDone ?? false
      case Resource.LT_CHALLENGE:
        return score?.legacyTrail?.videoWatched ?? false
      case Resource.LT_BADGE:
        return score?.legacyTrail?.quizTaken ?? false
      case Resource.BM_MAIN:
        return score?.legacyTrailDone ?? false
      case Resource.BM_CHALLENGE:
        return score?.beyondMasungi?.videoWatched ?? false
      case Resource.BM_BADGE:
        return score?.beyondMasungi?.quizTaken ?? false
      case Resource.SM_MAIN:
        return score?.beyondMasungiDone ?? false
      case Resource.SM_BADGE:
        return score?.specialMission.letterUploaded ?? false
      default:
        return false
    }
  }

  return (
    <Box className={styles.menuWrapper}>
      <Grid container justify="center" className={styles.menuGrid}>
        {activeMenu === Menu.HOME && (
          <>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() => handleMenuClick(Menu.DISCOVERY_TRAIL)}
                disabled={!hasUnlocked(Resource.DT_MAIN)}
              >
                <img
                  src={
                    hasUnlocked(Resource.DT_MAIN)
                      ? DiscoveryImg
                      : DiscoveryLockedImg
                  }
                  alt="Discovery Trail"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Discovery
                <br />
                Trail
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() => handleMenuClick(Menu.LEGACY_TRAIL)}
                disabled={!hasUnlocked(Resource.LT_MAIN)}
              >
                <img
                  src={
                    hasUnlocked(Resource.LT_MAIN) ? LegacyImg : LegacyLockedImg
                  }
                  alt="Legacy Trail"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Legacy
                <br />
                Trail
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() => handleMenuClick(Menu.BEYOND_MASUNGI)}
                disabled={!hasUnlocked(Resource.BM_MAIN)}
              >
                <img
                  src={
                    hasUnlocked(Resource.BM_MAIN) ? BeyondImg : BeyondLockedImg
                  }
                  alt="Beyond Masungi"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Beyond
                <br />
                Masungi
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() => handleMenuClick(Menu.SPECIAL_MISSION)}
                disabled={!hasUnlocked(Resource.SM_MAIN)}
              >
                <img
                  src={
                    hasUnlocked(Resource.SM_MAIN)
                      ? SpecialImg
                      : SpecialLockedImg
                  }
                  alt="Special Mission"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Special
                <br />
                Mission
              </Typography>
            </Grid>
          </>
        )}

        {activeMenu !== Menu.HOME && activeMenu !== Menu.SPECIAL_MISSION && (
          <>
            <Grid item className={styles.backItem}>
              <IconButton
                className={styles.menuButton}
                onClick={() => handleMenuClick(Menu.HOME)}
              >
                <img src={BackImg} alt="Home" className={styles.menuIcon} />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Back to Home
                <br />
                &nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() =>
                  onResourceClicked(
                    activeMenu === Menu.DISCOVERY_TRAIL
                      ? Resource.DT_MAIN
                      : activeMenu === Menu.LEGACY_TRAIL
                      ? Resource.LT_MAIN
                      : Resource.BM_MAIN
                  )
                }
              >
                <img
                  src={StartImg}
                  alt="Start Trail"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Start Trail
                <br />
                &nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() =>
                  onResourceClicked(
                    activeMenu === Menu.DISCOVERY_TRAIL
                      ? Resource.DT_CHALLENGE
                      : activeMenu === Menu.LEGACY_TRAIL
                      ? Resource.LT_CHALLENGE
                      : Resource.BM_CHALLENGE
                  )
                }
                disabled={
                  !hasUnlocked(
                    activeMenu === Menu.DISCOVERY_TRAIL
                      ? Resource.DT_CHALLENGE
                      : activeMenu === Menu.LEGACY_TRAIL
                      ? Resource.LT_CHALLENGE
                      : Resource.BM_CHALLENGE
                  )
                }
              >
                <img
                  src={
                    hasUnlocked(
                      activeMenu === Menu.DISCOVERY_TRAIL
                        ? Resource.DT_CHALLENGE
                        : activeMenu === Menu.LEGACY_TRAIL
                        ? Resource.LT_CHALLENGE
                        : Resource.BM_CHALLENGE
                    )
                      ? ChallengeImg
                      : ChallengeLockedImg
                  }
                  alt="Take Challenge"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Take Challenge
                <br />
                &nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() =>
                  onResourceClicked(
                    activeMenu === Menu.DISCOVERY_TRAIL
                      ? Resource.DT_BADGE
                      : activeMenu === Menu.LEGACY_TRAIL
                      ? Resource.LT_BADGE
                      : Resource.BM_BADGE
                  )
                }
                disabled={
                  !hasUnlocked(
                    activeMenu === Menu.DISCOVERY_TRAIL
                      ? Resource.DT_BADGE
                      : activeMenu === Menu.LEGACY_TRAIL
                      ? Resource.LT_BADGE
                      : Resource.BM_BADGE
                  )
                }
              >
                <img
                  src={
                    hasUnlocked(
                      activeMenu === Menu.DISCOVERY_TRAIL
                        ? Resource.DT_BADGE
                        : activeMenu === Menu.LEGACY_TRAIL
                        ? Resource.LT_BADGE
                        : Resource.BM_BADGE
                    )
                      ? BadgeImg
                      : BadgeLockedImg
                  }
                  alt="Get Badge"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Get Badge
                <br />
                &nbsp;
              </Typography>
            </Grid>
          </>
        )}

        {activeMenu === Menu.SPECIAL_MISSION && (
          <>
            <Grid item className={styles.backItem}>
              <IconButton
                className={styles.menuButton}
                onClick={() => handleMenuClick(Menu.HOME)}
              >
                <img src={BackImg} alt="Home" className={styles.menuIcon} />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Back to Home
                <br />
                &nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <IconButton className={styles.menuButton} onClick={() => {}}>
                <img
                  src={StartImg}
                  alt="Upload Letter"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Upload Letter
                <br />
                &nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={styles.menuButton}
                onClick={() => {}}
                disabled={!hasUnlocked(Resource.SM_BADGE)}
              >
                <img
                  src={
                    hasUnlocked(Resource.SM_BADGE) ? BadgeImg : BadgeLockedImg
                  }
                  alt="Get Badge"
                  className={styles.menuIcon}
                />
              </IconButton>
              <Typography className={styles.menuLabel}>
                Get Badge
                <br />
                &nbsp;
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default DockMenu
