export interface Student {
  id: string
  schoolId: string
  userId: string
  email: string
  firstName: string
  lastName: string
  gradeLevel: string
  section: string
  ranking: string
  remarks: string
  avatar: string
  badges: string[]
  score: StudentScore
  lastLoggedIn?: Date
}

interface StudentScore {
  discoveryTrailDone: boolean
  legacyTrailDone: boolean
  beyondMasungiDone: boolean
  specialMissionDone: boolean
  discoveryTrail: TrailScore
  legacyTrail: TrailScore
  beyondMasungi: TrailScore
  specialMission: {
    letterUploaded: boolean
    badgeTaken: boolean
    downloadLink?: string
    updatedAt?: Date
  }
  lastActivity?: Date
}

interface TrailScore {
  videoWatched: boolean
  quizTaken: boolean
  quizScore: number
  badgeTaken: boolean
  updatedAt?: Date
}

export const studentConverter: firebase.firestore.FirestoreDataConverter<Student> = {
  toFirestore: (student: Student) => {
    return {
      schoolId: student.schoolId,
      userId: student.userId,
      email: student.email,
      firstName: student.firstName,
      lastName: student.lastName,
      gradeLevel: student.gradeLevel,
      section: student.section,
      ranking: student.ranking,
      avatar: student.avatar,
      remarks: student.remarks,
      badges: student.badges,
      score: student.score,
      lastLoggedIn: student.lastLoggedIn
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      schoolId: data.schoolId,
      userId: data.userId,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      gradeLevel: data.gradeLevel,
      section: data.section,
      ranking: data.ranking,
      remarks: data.remarks,
      avatar: data.avatar,
      badges: data.badges,
      score: data.score
        ? {
            ...data.score,
            discoveryTrail: {
              ...data.score.discoveryTrail,
              updatedAt: data.score.discoveryTrail.updatedAt
                ? data.score.discoveryTrail.updatedAt.toDate()
                : undefined
            },
            legacyTrail: {
              ...data.score.legacyTrail,
              updatedAt: data.score.legacyTrail.updatedAt
                ? data.score.legacyTrail.updatedAt.toDate()
                : undefined
            },
            beyondMasungi: {
              ...data.score.beyondMasungi,
              updatedAt: data.score.beyondMasungi.updatedAt
                ? data.score.beyondMasungi.updatedAt.toDate()
                : undefined
            },
            specialMission: {
              ...data.score.specialMission,
              updatedAt: data.score.specialMission.updatedAt
                ? data.score.specialMission.updatedAt.toDate()
                : undefined
            },
            lastActivity: data.score.lastActivity
              ? data.score.lastActivity.toDate()
              : undefined
          }
        : {},
      lastLoggedIn: data.lastLoggedIn ? data.lastLoggedIn.toDate() : undefined
    }
  }
}
