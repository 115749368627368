import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../components/states/AppContextProvider'
import { Student } from '../../models/Student'
import { StudentFilters, StudentRepo } from '../../repos/StudentRepo'
import DownloadIcon from '@material-ui/icons/Description'
import XLSX from 'xlsx'
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Badges } from '../../config/enums'

interface DateFilters {
  relative?: string
  range?: {
    from?: Date
    to?: Date
  }
}

const Reports = () => {
  const school = useContext(AppContext).state.school
  const [studentRepo] = useState(new StudentRepo())
  const [students, setStudents] = useState<Student[]>([])
  const [dateFilters, setDateFilters] = useState<DateFilters>({
    relative: 'everything'
  })
  const [expandedDateFilter, setExpandedDateFilter] = useState<string>(
    'relative'
  )
  const [filters, setFilters] = useState<StudentFilters>({})
  const [savedFilters, setSavedFilters] = useState<StudentFilters>({})
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [expanded, setExpanded] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!school) return

    setIsLoading(true)

    studentRepo
      .getStudents(
        school.id,
        'lastName',
        'asc',
        1000,
        undefined,
        undefined,
        filters,
        getDateActive()
      )
      .then(s => {
        setStudents(s)
        console.log('setIsLoading: false')
        setIsLoading(false)
      })
      .catch(e => {
        console.error(e)
        setIsLoading(false)
        console.log('setIsLoading: false')
      })
  }, [school, savedFilters, dateFilters]) // eslint-disable-line

  const handleDownload = async () => {
    let data: string[][] = [
      [
        'First Name',
        'Last Name',
        'Grade',
        'Section',
        'Email',
        'Ranking',
        'Special Mission Accomplished',
        'Download Link'
      ]
    ]

    students.forEach(student => {
      data.push([
        student.firstName,
        student.lastName,
        student.gradeLevel,
        student.section,
        student.email,
        student.ranking,
        student.score.specialMissionDone ? 'Yes' : 'No',
        student.score.specialMission?.downloadLink ?? ''
      ])
    })

    const ws = XLSX.utils.aoa_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'Masungi 360° - Student Report.xlsx')
  }

  const clearFilters = () => {
    setFilters({})
    setSavedFilters({})
    setFiltersApplied(false)
    setExpanded(undefined)
  }

  const applyFilters = () => {
    if (!filters.gradeLevel && !filters.section && !filters.ranking) {
      return
    }

    setFiltersApplied(true)
    setSavedFilters(filters)
  }

  const getDateActive = () => {
    let dateActive:
      | {
          from: Date
          to: Date
        }
      | undefined

    if (dateFilters.relative) {
      const today = new Date(),
        earlyDate = new Date()

      switch (dateFilters.relative) {
        case 'last year':
          earlyDate.setFullYear(today.getFullYear() - 1)
          dateActive = {
            from: earlyDate,
            to: today
          }
          break
        case 'last 3 months':
          earlyDate.setMonth(today.getMonth() - 3)
          dateActive = {
            from: earlyDate,
            to: today
          }
          break
        case 'last 30 days':
          earlyDate.setDate(today.getDate() - 30)
          dateActive = {
            from: earlyDate,
            to: today
          }
          break
        case 'last 7 days':
          earlyDate.setDate(today.getDate() - 7)
          dateActive = {
            from: earlyDate,
            to: today
          }
          break
        case 'everything':
        default:
          break
      }
    } else if (dateFilters.range) {
      dateActive = {
        from: dateFilters.range.from!,
        to: dateFilters.range.to!
      }
    }

    return dateActive
  }

  return (
    <div>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">Reports</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={4}>
        <Grid item lg={3}>
          <Box mb={4}>
            <Typography gutterBottom={true}>Filter by date</Typography>
            <Paper>
              <Accordion
                defaultExpanded={dateFilters.relative !== undefined}
                expanded={expandedDateFilter === 'relative'}
                onChange={() => setExpandedDateFilter('relative')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Relative time</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Select
                    value={dateFilters.relative}
                    onChange={e =>
                      setDateFilters({ relative: e.target.value as string })
                    }
                    fullWidth={true}
                    variant="outlined"
                  >
                    <MenuItem value="everything">Everything</MenuItem>
                    <MenuItem value="last 7 days">Last 7 days</MenuItem>
                    <MenuItem value="last 30 days">Last 30 days</MenuItem>
                    <MenuItem value="last 3 months">Last 3 months</MenuItem>
                    <MenuItem value="last year">Last year</MenuItem>
                  </Select>
                </AccordionDetails>
              </Accordion>
              <Accordion
                defaultExpanded={dateFilters.range !== undefined}
                expanded={expandedDateFilter === 'range'}
                onChange={() => setExpandedDateFilter('range')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1b-content"
                  id="panel1b-header"
                >
                  <Typography>Date range</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DatePicker
                    value={dateFilters.range?.from ?? new Date()}
                    format="MM/DD/YYYY"
                    onChange={e => {
                      if (e)
                        setDateFilters({
                          range: { ...dateFilters.range, from: e.toDate() }
                        })
                    }}
                    label="From"
                    fullWidth={true}
                    inputVariant="outlined"
                  />
                  &nbsp;
                  <DatePicker
                    value={dateFilters.range?.to ?? new Date()}
                    format="MM/DD/YYYY"
                    onChange={e => {
                      if (e)
                        setDateFilters({
                          range: { ...dateFilters.range, to: e.toDate() }
                        })
                    }}
                    label="To"
                    fullWidth={true}
                    inputVariant="outlined"
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Box>
          <Box mb={4}>
            <Typography gutterBottom={true} component="div">
              <Badge
                color="primary"
                invisible={!filtersApplied}
                overlap="circle"
              >
                <FilterListIcon />
              </Badge>
              &nbsp;More filters
            </Typography>

            <Paper>
              <Accordion
                defaultExpanded={filters.gradeLevel !== undefined}
                expanded={expanded === 'gradeLevel'}
                onChange={() => setExpanded('gradeLevel')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Filter by grade level</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Enter grade level"
                    variant="outlined"
                    fullWidth={true}
                    value={filters.gradeLevel ?? ''}
                    onChange={e =>
                      setFilters({
                        gradeLevel: e.currentTarget.value
                      })
                    }
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                defaultExpanded={filters.section !== undefined}
                expanded={expanded === 'section'}
                onChange={() => setExpanded('section')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1b-content"
                  id="panel2b-header"
                >
                  <Typography>Filter by section</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Enter section"
                    variant="outlined"
                    fullWidth={true}
                    value={filters.section ?? ''}
                    onChange={e =>
                      setFilters({
                        section: e.currentTarget.value
                      })
                    }
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                defaultExpanded={filters.ranking !== undefined}
                expanded={expanded === 'ranking'}
                onChange={() => setExpanded('ranking')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  id="panel2c-header"
                >
                  <Typography>Filter by ranking</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Select
                    value={filters.ranking}
                    onChange={e =>
                      setFilters({ ranking: e.target.value as string })
                    }
                    fullWidth={true}
                    variant="outlined"
                  >
                    <MenuItem value="trainee">Trainee</MenuItem>
                    <MenuItem value={Badges.PRT}>{Badges.PRT}</MenuItem>
                    <MenuItem value={Badges.JPR}>{Badges.JPR}</MenuItem>
                    <MenuItem value={Badges.PR}>{Badges.PR}</MenuItem>
                    <MenuItem value={Badges.SA}>{Badges.SA}</MenuItem>
                  </Select>
                </AccordionDetails>
              </Accordion>
              <Box p={1} display="flex" justifyContent="space-between">
                <Button onClick={clearFilters}>Clear</Button>
                <Button color="primary" onClick={applyFilters}>
                  Apply
                </Button>
              </Box>
            </Paper>
          </Box>
        </Grid>

        <Grid item lg={9}>
          <Paper>
            {students.length > 0 ? (
              <Box>
                <Table className="table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>First Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Last Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Grade Level</b>
                      </TableCell>
                      <TableCell>
                        <b>Section</b>
                      </TableCell>
                      <TableCell>
                        <b>Email</b>
                      </TableCell>
                      <TableCell>
                        <b>Ranking</b>
                      </TableCell>
                      <TableCell>
                        <b>Special Mission Accomplished</b>
                      </TableCell>
                      <TableCell>
                        <b>Download Link</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map(student => {
                      return (
                        <TableRow key={student.email}>
                          <TableCell>{student.firstName}</TableCell>
                          <TableCell>{student.lastName}</TableCell>
                          <TableCell>{student.gradeLevel}</TableCell>
                          <TableCell>{student.section}</TableCell>
                          <TableCell>{student.email}</TableCell>
                          <TableCell>{student.ranking}</TableCell>
                          <TableCell>
                            {student.score.specialMissionDone ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell>
                            {student.score.specialMission?.downloadLink && (
                              <a
                                href={
                                  student.score.specialMission?.downloadLink ??
                                  '#'
                                }
                                target="_blank"
                              >
                                Download
                              </a>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box p={3} textAlign="center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography>No match on your filter criteria</Typography>
                )}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Reports
