import React, { useContext, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Box,
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { useHistory } from 'react-router'
import { AppContext } from '../states/AppContextProvider'
import { UserRole } from '../../models/UserProfile'
import { useSnackbar } from 'notistack'
import { onResetPassword } from '../../services/UserService'

interface Values {
  password: string
  confirmPassword: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: 'center'
    },
    field: {
      width: '100%',
      '& input': {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
      }
    }
  })
)

const FirebaseResetPassword = () => {
  const appState = useContext(AppContext).state
  const styles = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!appState.user) {
      history.replace('/')
    }
  }, [appState.user, history])

  const validate = (values: Values) => {
    const errors: Partial<Values> = {}
    if (!values.password) {
      errors.password = 'Required'
    } else if (values.password.length < 6) {
      errors.password = 'Password must be at least 6 characters'
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords didn't matched"
    }
    return errors
  }

  const reset = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    if (!appState.user) return

    try {
      await appState.user.updatePassword(values.password)

      enqueueSnackbar('Your password has been updated!', {
        variant: 'success'
      })

      if (!appState.user.emailVerified) {
        await onResetPassword(appState.user.uid)
      }

      if (appState.userRole === UserRole.school) {
        history.replace('/school')
      } else if (appState.userRole === UserRole.admin) {
        history.replace('/admin')
      } else {
        history.replace('/')
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
      setSubmitting(false)
    }
  }

  return (
    <div className={styles.root}>
      <Typography variant="h6" component="h2">
        Reset Password
      </Typography>

      <Box my={2}>
        <Typography>Please enter your new password.</Typography>
      </Box>

      <Formik
        initialValues={{
          password: '',
          confirmPassword: ''
        }}
        validate={values => {
          return validate(values)
        }}
        onSubmit={(values, { setSubmitting }) => {
          reset(values, setSubmitting)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mb={1}>
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                className={styles.field}
              />
            </Box>
            <Box mb={6}>
              <Field
                component={TextField}
                name="confirmPassword"
                type="password"
                label="Repeat Password"
                className={styles.field}
              />
            </Box>
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
              fullWidth={true}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FirebaseResetPassword
