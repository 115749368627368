import React, { useEffect, useState } from 'react'
import Clappr from 'clappr'
import Video360 from 'clappr-video360'
import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { TrailType } from '../../config/enums'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      '& canvas': {
        width: '100% !important',
        height: 'auto !important',
        margin: 'auto 0 !important'
      }
    },
    msg: {
      textAlign: 'center',
      position: 'absolute',
      top: '80%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  })
)

const sources: { [key: string]: string } = {
  [TrailType.DiscoveryTrail]:
    'https://firebasestorage.googleapis.com/v0/b/masungi-360.appspot.com/o/videos%2F%20Discovery%20Trail%20Video.mp4?alt=media&token=eba16017-28cb-47e6-ba3d-e08edf498f05',
  [TrailType.LegacyTrail]:
    'https://firebasestorage.googleapis.com/v0/b/masungi-360.appspot.com/o/videos%2FLegacy%20Trail%20v3.mp4?alt=media&token=7d944cdd-ec6e-4b42-b771-e91165a96cbc',
  [TrailType.BeyondMasungi]:
    'https://firebasestorage.googleapis.com/v0/b/masungi-360.appspot.com/o/videos%2FBeyond%20Masungi%20v3.mp4?alt=media&token=1eb8b7cc-0619-4de8-93d9-c26e60cd8559'
}

interface PanoramicVideoPlayerProps {
  type: TrailType
  onVideoEnded: () => void
}

const PanoramicVideoPlayer = ({
  type,
  onVideoEnded
}: PanoramicVideoPlayerProps) => {
  const styles = useStyles()
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    // Initialize panomaric video player using clappr
    const player = new Clappr.Player({
      source: sources[type],
      plugins: {
        container: [Video360]
      },
      parentId: '#player',
      // width: '800',
      // height: '450',
      events: {
        // onReady: () => {
        //   console.log('onReady')
        // }, //Fired when the player is ready on startup
        // onResize: () => {
        //   console.log('onResize')
        // }, //Fired when player resizes
        // onPause: () => {
        //   console.log('onPause')
        // }, //Fired when player pauses
        // onStop: () => {
        //   console.log('onStop')
        // }, //Fired when player stops
        // onSeek: () => {
        //   console.log('onSeek')
        // }, //Fired when player seeks the video
        // onError: () => {
        //   console.log('onError')
        // }, //Fired when player receives an error
        // onTimeUpdate: () => {
        //   console.log('onTimeUpdate')
        // }, //Fired when the time is updated on player
        // onVolumeUpdate: () => {
        //   console.log('onVolumeUpdate')
        // }, //Fired when player updates its volume
        // onSubtitleAvailable: () => {
        //   console.log('onSubtitleAvailable')
        // }, //Fired when subtitles is available
        onPlay: () => {
          setShowMessage(false)
        },
        onEnded: onVideoEnded
      }
    })

    // for better usability, disable clappr's click_to_pause plugin
    player.getPlugin('click_to_pause').disable()

    // triggers after user clicks the play button the first time
    player.core.activePlayback.on(Clappr.Events.PLAYBACK_PLAY_INTENT, () => {
      setShowMessage(true)
    })
  }, [])

  return (
    <div className={styles.root}>
      <div id="player"></div>
      {showMessage && (
        <Typography className={styles.msg}>
          This video may take a few seconds to load!
          <br />
          Please stand by.
        </Typography>
      )}
    </div>
  )
}

export default PanoramicVideoPlayer
