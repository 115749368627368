import React from 'react'
import AuthLayout from '../components/layout/AuthLayout'
import FirebaseResetPassword from '../components/auth/FirebaseResetPassword'

const ResetPassword = () => {
  return (
    <AuthLayout>
      <FirebaseResetPassword />
    </AuthLayout>
  )
}

export default ResetPassword
