import React, { useEffect, useState } from 'react'
import { createStyles, Hidden, makeStyles } from '@material-ui/core'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import ReactPannellum, { addScene, loadScene } from 'react-pannellum'
import GuideMobileImg from '../assets/360-guide-mobile.png'
import GuideDesktopImg from '../assets/360-guide-desktop.png'
import DockMenu, { Menu, Resource } from '../components/layout/DockMenu'
import StartTrail from '../components/student/StartTrail'
import { TrailType } from '../config/enums'
import TakeChallenge from '../components/student/TakeChallenge'
import GetBadge from '../components/student/GetBadge'

const useStyles = makeStyles(theme =>
  createStyles({
    guide: {
      position: 'absolute',
      right: 30,
      top: 100
    }
  })
)

const Home = () => {
  const [activeMenu, setActiveMenu] = useState(Menu.HOME)
  const [activityVisibility, setActivityVisibility] = useState({
    startTrail: false,
    takeQuiz: false,
    getBadge: false
  })

  const config = {
    autoLoad: true,
    showControls: false,
    autoRotate: -1,
    autoRotateInactivityDelay: 4000,
    // hotSpots: [
    //   {
    //     yaw: -95,
    //     pitch: -30,
    //     type: 'info',
    //     text: 'Hello'
    //   }
    // ],
    preview: 'home360-placeholder.jpg',
    yaw: 332,
    pitch: -20,
    hfov: 100
  }

  useEffect(() => {
    // Add panelleum scenes
    // This allows us to change the displayed 360 photo when a menu is clicked
    addScene('discoveryScene', {
      yaw: 225,
      pitch: -22,
      hfov: 100,
      type: 'equirectangular',
      imageSource: 'discovery360.JPG',
      preview: 'discovery360-placeholder.jpg',
      showControls: false,
      autoRotate: -1,
      autoRotateInactivityDelay: 4000
    })
    addScene('legacyScene', {
      yaw: 20,
      pitch: -22,
      hfov: 100,
      type: 'equirectangular',
      imageSource: 'legacy360.JPG',
      preview: 'legacy360-placeholder.jpg',
      showControls: false,
      autoRotate: -1,
      autoRotateInactivityDelay: 4000
    })
    addScene('beyondMasungiScene', {
      yaw: 240,
      pitch: -10,
      hfov: 100,
      type: 'equirectangular',
      imageSource: 'beyondMasungi360.JPG',
      preview: 'beyondMasungi360-placeholder.jpg',
      showControls: false,
      autoRotate: -1,
      autoRotateInactivityDelay: 4000
    })
  }, [])

  const handleMenuChange = (menu: Menu) => {
    setActiveMenu(menu)

    switch (menu) {
      case Menu.DISCOVERY_TRAIL:
        loadScene('discoveryScene')
        break
      case Menu.LEGACY_TRAIL:
        loadScene('legacyScene')
        break
      case Menu.BEYOND_MASUNGI:
        loadScene('beyondMasungiScene')
        break
      default:
        loadScene('homeScene')
        break
    }
  }

  const handleResourceClick = (resource: Resource) => {
    switch (resource) {
      case Resource.DT_MAIN:
      case Resource.LT_MAIN:
      case Resource.BM_MAIN:
        setActivityVisibility({
          startTrail: true,
          takeQuiz: false,
          getBadge: false
        })
        return
      case Resource.DT_CHALLENGE:
      case Resource.LT_CHALLENGE:
      case Resource.BM_CHALLENGE:
        setActivityVisibility({
          startTrail: false,
          takeQuiz: true,
          getBadge: false
        })
        return
      case Resource.DT_BADGE:
      case Resource.LT_BADGE:
      case Resource.BM_BADGE:
        setActivityVisibility({
          startTrail: false,
          takeQuiz: false,
          getBadge: true
        })
        return
    }
  }

  return (
    <FrontsiteLayout>
      {activityVisibility.startTrail && (
        <StartTrail
          onClose={() =>
            setActivityVisibility({
              startTrail: false,
              takeQuiz: false,
              getBadge: false
            })
          }
          onComplete={doNextStep => {
            setActivityVisibility({
              startTrail: false,
              takeQuiz: doNextStep,
              getBadge: false
            })
          }}
          type={
            activeMenu === Menu.LEGACY_TRAIL
              ? TrailType.LegacyTrail
              : activeMenu === Menu.BEYOND_MASUNGI
              ? TrailType.BeyondMasungi
              : TrailType.DiscoveryTrail
          }
        />
      )}

      {activityVisibility.takeQuiz && (
        <TakeChallenge
          onClose={() =>
            setActivityVisibility({
              startTrail: false,
              takeQuiz: false,
              getBadge: false
            })
          }
          onComplete={doNextStep => {
            setActivityVisibility({
              startTrail: false,
              takeQuiz: false,
              getBadge: doNextStep
            })
          }}
          type={
            activeMenu === Menu.LEGACY_TRAIL
              ? TrailType.LegacyTrail
              : activeMenu === Menu.BEYOND_MASUNGI
              ? TrailType.BeyondMasungi
              : TrailType.DiscoveryTrail
          }
        />
      )}

      {activityVisibility.getBadge && (
        <GetBadge
          onClose={() =>
            setActivityVisibility({
              startTrail: false,
              takeQuiz: false,
              getBadge: false
            })
          }
          type={
            activeMenu === Menu.LEGACY_TRAIL
              ? TrailType.LegacyTrail
              : activeMenu === Menu.BEYOND_MASUNGI
              ? TrailType.BeyondMasungi
              : TrailType.DiscoveryTrail
          }
        />
      )}

      <div>
        <ReactPannellum
          id="1"
          sceneId="homeScene"
          imageSource="home360.JPG"
          config={config}
          style={{
            width: '100vw',
            height: 'calc(100vh - 64px)',
            background: '#FFFFFF',
            position: 'absolute'
          }}
        />

        <PannellumGuide />

        <DockMenu
          onMenuChange={handleMenuChange}
          onResourceClicked={handleResourceClick}
        />
      </div>
    </FrontsiteLayout>
  )
}

const PannellumGuide = () => {
  const styles = useStyles()

  return (
    <div className={styles.guide}>
      <Hidden lgUp>
        <img src={GuideMobileImg} alt="360 view" width={60} />
      </Hidden>
      <Hidden mdDown>
        <img src={GuideDesktopImg} alt="360 view" width={80} />
      </Hidden>
    </div>
  )
}

export default Home
