import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../components/states/AppContextProvider'
import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import SchoolIcon from '@material-ui/icons/AccountBalance'
import StudentsIcon from '@material-ui/icons/School'
import ActiveIcon from '@material-ui/icons/LocalActivity'
import EmailIcon from '@material-ui/icons/Email'
import { getSchoolDetail } from '../../services/SchoolService'
import { ActionType } from '../../components/states/AppReducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrapper: {
      padding: 20,
      marginTop: '-20px',
      marginLeft: 20,
      position: 'absolute',
      backgroundColor: 'green',
      color: 'white',
      borderRadius: 4,
      boxShadow: '2px 2px 4px #ccc'
    }
  })
)

const Dashboard = () => {
  const classes = useStyles()
  const context = useContext(AppContext)
  const appState = context.state
  const school = appState.school
  const [hasRefreshed, setHasRefreshed] = useState(false)

  useEffect(() => {
    if (!appState.school) return
    if (hasRefreshed) return

    // Fetch fresh school details from db once on each load
    setHasRefreshed(true)
    getSchoolDetail(appState.school.id).then(freshSchool => {
      context.dispatch({ type: ActionType.setSchool, payload: freshSchool })
    })
  }, [appState.school, hasRefreshed]) // eslint-disable-line

  return (
    <div>
      <Box mb={6}>
        <Typography variant="h5">Dashboard</Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <SchoolIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  Capacity
                </Typography>
                <Typography variant="h3" align="right">
                  {school?.capacity ?? 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <StudentsIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  Students Enrolled
                </Typography>
                <Typography variant="h3" align="right">
                  {school?.enrolled ?? 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <ActiveIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  Active Accounts
                </Typography>
                <Typography variant="h3" align="right">
                  {school?.active ?? 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <EmailIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  Letters Uploaded
                </Typography>
                <Typography variant="h3" align="right">
                  {school?.lettersUploaded ?? 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Dashboard
