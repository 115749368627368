import React, { useEffect, useState } from 'react'
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { Badges } from '../config/enums'
import { badgeOfficialName, getByEmail } from '../services/StudentService'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      height: 'calc(100vh - 64px)'
    },
    sidebar: {
      backgroundColor: '#505d37'
    },
    mainContent: {
      backgroundColor: '#fcf4da',
      display: 'flex',
      alignItems: 'center'
    },
    certificateTitle: {
      textAlign: 'center',
      '& img': {
        width: 90
      }
    },
    leading: {
      lineHeight: '4rem'
    },
    name: {
      textTransform: 'uppercase'
    },
    subtitle: {
      fontSize: '1.75rem'
    },
    badge: {
      width: 'auto',
      maxWidth: '90%'
    },
    bottomLogo: {
      marginTop: '2rem'
    }
  })
)

const Badge = () => {
  let { badgeName, email } = useParams<{ badgeName: string; email: string }>()
  const history = useHistory()
  const [decodedEmail, setDecodedEmail] = useState<string>()
  const [fullName, setFullName] = useState<string>()
  const classes = useStyles()

  useEffect(() => {
    if (!history) return

    if (
      !(
        badgeName === Badges.JPR ||
        badgeName === Badges.PRT ||
        badgeName === Badges.PR ||
        badgeName === Badges.SA
      )
    ) {
      // Invalid badge name, redirect to home page
      history.replace('/')
    }
  }, [badgeName])

  useEffect(() => {
    const emailDecoded = atob(email)
    setDecodedEmail(atob(email))

    getByEmail(emailDecoded)
      .then(student => {
        if (!student) return
        setFullName(`${student.firstName} ${student.lastName}`)
      })
      .catch(err => {
        console.error(err)
      })
  }, [email])

  return (
    <FrontsiteLayout>
      <Grid container className={classes.wrapper}>
        <Grid item md={3} lg={2} className={classes.sidebar}>
          <Box py={8} textAlign="center">
            <img height={220} src="/full-logo.png" alt="Masungi 360 logo" />
          </Box>
          <div className={classes.certificateTitle}>
            <img
              src={`/certificate-title.png`}
              alt="Certification of Completion"
            />
          </div>
        </Grid>
        <Grid item md={9} lg={10} className={classes.mainContent}>
          <Box px={8}>
            <Grid container>
              <Grid item lg={8}>
                <Typography variant="h4" className={classes.leading}>
                  <b className={classes.name}>{fullName ?? decodedEmail}</b> is
                  now
                  <br /> an honorary Masungi Georeserve
                  <br />
                  {badgeOfficialName(badgeName)}.
                </Typography>
                <Box mt={8}>
                  <Typography variant="h6" className={classes.subtitle}>
                    Start your own journey towards becoming an honorary Masungi
                    Georeserve {badgeOfficialName(badgeName)} by joining Masungi
                    360° website today!
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <img
                  src={`/badges/${badgeName}.png`}
                  className={classes.badge}
                  alt={badgeName}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.bottomLogo}>
                  <img src={`/logo-black.png`} alt="Masungi Georeserve" />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </FrontsiteLayout>
  )
}

export default Badge
