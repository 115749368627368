import React from 'react'
import {
  Card,
  CardContent,
  Container,
  createStyles,
  makeStyles
} from '@material-ui/core'
import FirebaseLogin from '../components/auth/FirebaseLogin'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import { UserRole } from '../models/UserProfile'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      backgroundImage: 'url("/login-bg.jpg")',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '100vw',
      height: 'calc(100vh - 64px)',
      display: 'flex',
      alignItems: 'center'
    },
    modal: {
      // backgroundColor: '#4e5c37'
    }
  })
)

const Login = () => {
  const styles = useStyles()

  return (
    <FrontsiteLayout>
      <div className={styles.wrapper}>
        <Container maxWidth="sm">
          <Card className={styles.modal}>
            <CardContent>
              <FirebaseLogin role={UserRole.student} />
            </CardContent>
          </Card>
        </Container>
      </div>
    </FrontsiteLayout>
  )
}

export default Login
