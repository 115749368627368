import React, { useContext, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Box,
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { fbAuth } from '../../services/FirebaseService'
import { useHistory } from 'react-router'
import { AppContext } from '../states/AppContextProvider'
import { UserRole } from '../../models/UserProfile'
import { useSnackbar } from 'notistack'
import { EMAIL_REGEX } from '../../config/constants'

interface Values {
  email: string
  password: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: '1rem'
    },
    field: {
      width: '100%'
    },
    passwordField: {
      '& input': {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
      }
    }
  })
)

interface FirebaseLoginProps {
  role: UserRole
  title?: string
}

const FirebaseLogin = ({ role, title }: FirebaseLoginProps) => {
  const appState = useContext(AppContext).state
  const styles = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!history || !enqueueSnackbar) return

    if (appState.user && appState.userRole) {
      if (appState.userRole !== role) {
        // Used a user account with a different role
        // (e.g. student trying to access admin portal)
        enqueueSnackbar('You are not permitted to access this resource.', {
          variant: 'error'
        })
        fbAuth().signOut()
        history.replace('/')
        return
      }

      // enqueueSnackbar(`Welcome back!`, {
      //   variant: 'success'
      // })

      if (appState.userRole === UserRole.school) {
        if (!appState.user.emailVerified) {
          enqueueSnackbar('Please set your password.', { variant: 'info' })
          history.replace('/school/resetPassword')
        } else {
          history.replace('/school')
        }
      } else if (appState.userRole === UserRole.admin) {
        history.replace('/admin')
      } else {
        if (!appState.user.emailVerified) {
          enqueueSnackbar('Please set your password.', { variant: 'info' })
          history.replace('/resetPassword')
        } else {
          history.replace('/')
        }
      }
    }
  }, [appState.user, appState.userRole, history, role, enqueueSnackbar])

  const validate = (values: Values) => {
    const errors: Partial<Values> = {}
    if (!values.email) {
      errors.email = 'Required'
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    if (!values.password) {
      errors.password = 'Required'
    }
    return errors
  }

  const login = async (
    values: Values,
    setSubmitting: (submitting: boolean) => void
  ) => {
    try {
      const res = await fbAuth().signInWithEmailAndPassword(
        values.email,
        values.password
      )
      if (!res.user) {
        enqueueSnackbar('Account not found from server response.', {
          variant: 'error'
        })
      } // else, useEffect will handle redirection according to user role
    } catch (err) {
      enqueueSnackbar('Invalid email or password.', {
        variant: 'error'
      })
      setSubmitting(false)
    }
  }

  const handleForgotPassword = () => {
    history.replace('/forgotPassword')
  }

  return (
    <div className={styles.root}>
      {title && (
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      )}

      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validate={values => {
          return validate(values)
        }}
        onSubmit={(values, { setSubmitting }) => {
          login(values, setSubmitting)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Box mb={1}>
              <Field
                component={TextField}
                name="email"
                type="email"
                label="Email"
                className={styles.field}
              />
            </Box>
            <Box mb={6}>
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                className={`${styles.field} ${styles.passwordField}`}
              />
            </Box>
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
              fullWidth={true}
              size="large"
            >
              Sign In
            </Button>
            <Box mt={3}>
              <Button size="small" onClick={handleForgotPassword}>
                Forgot your password?
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default FirebaseLogin
