import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'

interface UploadLetterSuccessProps {
  isOpen: boolean
  onDismiss: () => void
}

const UploadLetterSuccess = ({
  isOpen,
  onDismiss
}: UploadLetterSuccessProps) => {
  return (
    <Dialog open={isOpen} onClose={onDismiss} maxWidth="sm" fullWidth>
      <DialogTitle>Letter Uploaded!</DialogTitle>
      <DialogContent>
        <Typography align="center">
          Thank you for submitting your contribution to the #SaveMasungi 10,000
          Letters Campaign! Your letter will help us engage our leaders and
          stakeholders in the conservation of Philippine forests, specifically,
          the Upper Marikina Watershed and Masungi Georeserve.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box p={2} flex={1} textAlign="center">
          <Button variant="contained" onClick={onDismiss}>
            Dismiss
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default UploadLetterSuccess
