import React from 'react'
import {
  Card,
  CardContent,
  Container,
  createStyles,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      backgroundImage: 'url("/login-bg.jpg")',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center'
    }
  })
)

const AuthLayout: React.FC = ({ children }) => {
  const styles = useStyles()

  return (
    <div className={styles.wrapper}>
      <Container maxWidth="sm">
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default AuthLayout
