export interface School {
  id: string
  name: string
  location: string
  capacity: number
  remarks: string
  enrolled: number
  active: number
  lettersUploaded: number
  admin: {
    firstName: string
    lastName: string
    email: string
    phonenumber: string
    designation: string
  }
  stats: {
    studentsCount: number
  }
}

export const schoolConverter: firebase.firestore.FirestoreDataConverter<School> = {
  toFirestore: (school: School) => {
    return {
      name: school.name,
      location: school.location,
      capacity: school.capacity,
      remarks: school.remarks,
      enrolled: school.enrolled,
      active: school.active,
      lettersUploaded: school.lettersUploaded,
      admin: school.admin,
      stats: school.stats
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      name: data.name,
      location: data.location,
      capacity: data.capacity,
      remarks: data.remarks,
      enrolled: data.enrolled,
      active: data.active,
      lettersUploaded: data.lettersUploaded,
      admin: data.admin,
      stats: data.stats
    }
  }
}
