import { School, schoolConverter } from '../models/School'
import { fbDB } from '../services/FirebaseService'

export class SchoolRepo {
  private schoolDocs: firebase.firestore.QueryDocumentSnapshot<School>[] = []

  async getSchools(
    orderBy: string,
    order: 'asc' | 'desc',
    limit: number,
    beforeId?: string,
    afterId?: string,
    name?: string
  ) {
    let query = fbDB()
      .collection('schools')
      .withConverter(schoolConverter)
      .orderBy(orderBy, order)

    if (beforeId || afterId) {
      const searchId = afterId ? afterId : beforeId
      const filtered = this.schoolDocs.filter(doc => {
        return doc.data().id === searchId
      })

      if (filtered.length) {
        if (afterId) {
          query = query.startAfter(filtered[0]).limit(limit)
        } else {
          query = query.endBefore(filtered[0]).limitToLast(limit)
        }
      }
    } else {
      query = query.limit(limit)
    }

    if (name) {
      query = query.where('keywords.name', 'array-contains', name.toLowerCase())
    }

    const res = await query.get()
    this.schoolDocs = res.docs

    let schools: School[] = []
    res.docs.forEach(s => schools.push(s.data()))

    return schools
  }
}
