import React, { useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogActions,
  Box,
  Button,
  makeStyles,
  createStyles
} from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Formik, Form, Field } from 'formik'
import { AppContext } from '../states/AppContextProvider'
import { fbAuth } from '../../services/FirebaseService'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme =>
  createStyles({
    field: {
      width: '100%',
      '& input': {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
      }
    },
    cancelButton: {
      marginRight: '1rem'
    }
  })
)

interface Values {
  password: string
  newPassword: string
  repeatPassword: string
}

interface FirebaseChangePasswordProps {
  isOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

const FirebaseChangePassword = ({
  isOpen,
  onCancel,
  onSuccess
}: FirebaseChangePasswordProps) => {
  const styles = useStyles()
  const user = useContext(AppContext).state.user
  const { enqueueSnackbar } = useSnackbar()

  const validate = (values: Values) => {
    const errors: any = {}
    if (!values.password) {
      errors.password = 'Required'
    }
    if (!values.newPassword) {
      errors.newPassword = 'Required'
    }
    if (!values.repeatPassword) {
      errors.repeatPassword = 'Required'
    }
    if (values.newPassword !== values.repeatPassword) {
      errors.repeatPassword = "Passwords didn't matched"
    }

    return errors
  }

  const changePassword = async (
    password: string,
    newPassword: string,
    setSubmitting: (submitting: boolean) => void
  ) => {
    if (!user) return

    const creds = fbAuth.EmailAuthProvider.credential(
      user.email as string,
      password
    )

    try {
      // Check if password provided is valid
      await user.reauthenticateWithCredential(creds)

      // Proceed with updating the password
      await user.updatePassword(newPassword)

      enqueueSnackbar('Password successfully changed!', { variant: 'success' })
      setSubmitting(false)
      onSuccess()
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' })
      setSubmitting(false)
    }
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <Formik
        initialValues={{
          password: '',
          newPassword: '',
          repeatPassword: ''
        }}
        validate={values => {
          return validate(values)
        }}
        onSubmit={(values, { setSubmitting }) => {
          changePassword(values.password, values.newPassword, setSubmitting)
        }}
      >
        {({ submitForm, isSubmitting, setSubmitting }) => (
          <Form>
            <DialogContent>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="password"
                  type="password"
                  label="Current Password"
                  className={styles.field}
                />
              </Box>
              <Box mb={1}>
                <Field
                  component={TextField}
                  name="newPassword"
                  type="password"
                  label="New Password"
                  className={styles.field}
                />
              </Box>
              <Box mb={6}>
                <Field
                  component={TextField}
                  name="repeatPassword"
                  type="password"
                  label="Repeat Password"
                  className={styles.field}
                />
              </Box>
              {isSubmitting && <LinearProgress />}
            </DialogContent>
            <DialogActions>
              <Box textAlign="center" p={2} flex={1}>
                <Button
                  variant="contained"
                  onClick={onCancel}
                  className={styles.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Save
                </Button>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default FirebaseChangePassword
