import {
  Box,
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import FirebaseChangePassword from '../components/auth/FirebaseChangePassword'
import FrontsiteLayout from '../components/layout/FrontsiteLayout'
import { AppContext } from '../components/states/AppContextProvider'
import SelectAvatar from '../components/student/SelectAvatar'
import { Student } from '../models/Student'
import { fbAuth } from '../services/FirebaseService'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import { Badges } from '../config/enums'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingTop: '20px'
    },
    logout: {
      color: 'darkred'
    },
    passwordField: {
      '& input': {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
      }
    },
    sectionTitle: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main
    }
  })
)

const Profile = () => {
  const appState = useContext(AppContext).state
  const user = appState.user
  const styles = useStyles()
  const history = useHistory()
  const profile = appState.userProfile as Student
  const classes = useStyles()

  const [showSelectAvatar, setShowSelectAvatar] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [badges, setBadges] = useState<Badges[]>([])

  useEffect(() => {
    if (!history) return

    if (!user) {
      history.replace('/login')
    }
  }, [user, history])

  useEffect(() => {
    if (!profile) return

    const awards: Badges[] = []

    if (profile.score.discoveryTrailDone) {
      awards.push(Badges.PRT)
    }
    if (profile.score.legacyTrailDone) {
      awards.push(Badges.JPR)
    }
    if (profile.score.beyondMasungiDone) {
      awards.push(Badges.PR)
    }
    if (profile.score.specialMissionDone) {
      awards.push(Badges.SA)
    }

    setBadges(awards)
  }, [profile])

  return (
    <FrontsiteLayout>
      <Container>
        <SelectAvatar
          isOpen={showSelectAvatar}
          onCancel={() => setShowSelectAvatar(false)}
          onSuccess={() => {
            setShowSelectAvatar(false)
          }}
        />

        <FirebaseChangePassword
          isOpen={showChangePassword}
          onCancel={() => setShowChangePassword(false)}
          onSuccess={() => {
            setShowChangePassword(false)
          }}
        />

        <div className={styles.root}>
          <Typography
            variant="h5"
            component="h2"
            className={classes.sectionTitle}
          >
            My Profile
          </Typography>

          <Box mt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={5}>
                <Box textAlign="center">
                  <img
                    width={175}
                    height={175}
                    src={`/avatars/${profile?.avatar ?? 'avatar-0'}.png`}
                    alt="avatar"
                  ></img>
                </Box>
                <Box textAlign="center" mt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowSelectAvatar(true)}
                  >
                    Change Avatar
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12} sm={7}>
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Typography>Name</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      value={`${profile?.firstName} ${profile?.lastName}`}
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Typography>Section</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        value={`${profile?.gradeLevel} ${profile?.section}`}
                        fullWidth
                        disabled
                      ></TextField>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Typography>Email</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        value={profile?.email}
                        fullWidth
                        disabled
                      ></TextField>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={3}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Typography>Password</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <TextField
                        type="password"
                        value={profile?.id}
                        fullWidth
                        disabled
                        className={styles.passwordField}
                      ></TextField>
                      <Box mt={2} textAlign="right">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setShowChangePassword(true)}
                        >
                          Change password
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={10}>
            <Typography
              variant="h5"
              component="h2"
              className={classes.sectionTitle}
            >
              My Badges
            </Typography>
          </Box>

          <Box mt={4}>
            {badges.length > 0 ? (
              <Grid container spacing={3}>
                {badges.map(badge => {
                  return (
                    <Grid item xs={6} md={3} lg={2}>
                      <Box textAlign="center">
                        <img
                          width="100%"
                          src={`/badges/${badge}.png`}
                          alt={badge}
                        />
                        {/* <Typography variant="caption">{badge}</Typography> */}
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            ) : (
              <Typography>You have not collected any badges yet.</Typography>
            )}
          </Box>

          <Box mt={8} mb={4} textAlign="center">
            <Button
              size="small"
              onClick={() => {
                fbAuth().signOut()
                history.replace('/')
              }}
              startIcon={<LogoutIcon />}
              className={styles.logout}
            >
              Sign out
            </Button>
          </Box>
        </div>
      </Container>
    </FrontsiteLayout>
  )
}

export default Profile
