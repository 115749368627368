import { Student, studentConverter } from '../models/Student'
import { fbDB } from '../services/FirebaseService'

export interface StudentFilters {
  firstName?: string
  lastName?: string
  gradeLevel?: string
  section?: string
  email?: string
  ranking?: string
}

export class StudentRepo {
  private studentDocs: firebase.firestore.QueryDocumentSnapshot<Student>[] = []

  async getStudents(
    schoolId: string,
    orderBy: string,
    order: 'asc' | 'desc',
    limit: number,
    beforeId?: string,
    afterId?: string,
    filters?: StudentFilters,
    dateActive?: {
      from: Date
      to: Date
    }
  ) {
    // Override sorting params when filtering
    if (filters) {
      order = 'asc'
      if (dateActive) {
        orderBy = 'lastLoggedIn'
      } else if (filters.firstName) {
        orderBy = 'firstName'
      } else if (filters.lastName) {
        orderBy = 'lastName'
      } else if (filters.gradeLevel) {
        orderBy = 'gradeLevel'
      } else if (filters.section) {
        orderBy = 'section'
      } else if (filters.email) {
        orderBy = 'email'
      } else if (filters.ranking) {
        orderBy = 'ranking'
      }
    }

    let query = fbDB()
      .collection('students')
      .where('schoolId', '==', schoolId)
      .withConverter(studentConverter)
      .orderBy(orderBy, order)

    if (beforeId || afterId) {
      const searchId = afterId ? afterId : beforeId
      const filtered = this.studentDocs.filter(doc => {
        return doc.data().id === searchId
      })

      if (filtered.length) {
        if (afterId) {
          query = query.startAfter(filtered[0]).limit(limit)
        } else {
          query = query.endBefore(filtered[0]).limitToLast(limit)
        }
      }
    } else {
      query = query.limit(limit)
    }

    if (filters?.firstName) {
      query = query.where(
        'keywords.firstName',
        'array-contains',
        filters.firstName.toLowerCase()
      )
    } else if (filters?.lastName) {
      query = query.where(
        'keywords.lastName',
        'array-contains',
        filters.lastName.toLowerCase()
      )
    } else if (filters?.gradeLevel) {
      query = query.where(
        'keywords.gradeLevel',
        'array-contains',
        filters.gradeLevel.toLowerCase()
      )
    } else if (filters?.section) {
      query = query.where(
        'keywords.section',
        'array-contains',
        filters.section.toLowerCase()
      )
    } else if (filters?.email) {
      query = query.where(
        'keywords.email',
        'array-contains',
        filters.email.toLowerCase()
      )
    } else if (filters?.ranking) {
      query = query.where(
        'keywords.ranking',
        'array-contains',
        filters.ranking.toLowerCase()
      )
    }

    console.log('dateActive:', dateActive)
    if (dateActive) {
      query = query
        .where('lastLoggedIn', '>=', dateActive.from)
        .where('lastLoggedIn', '<=', dateActive.to)
    }

    console.log('executing firestore query...')
    const res = await query.get()
    console.log('response received!')
    this.studentDocs = res.docs

    let students: Student[] = []
    res.docs.forEach(s => students.push(s.data()))

    return students
  }
}
