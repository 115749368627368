import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import SchoolIcon from '@material-ui/icons/SchoolOutlined'
import StudentsIcon from '@material-ui/icons/PeopleOutline'
import ReportsIcon from '@material-ui/icons/BarChartOutlined'

export const adminRoutes = [
  {
    title: 'Dashboard',
    url: '/admin',
    icon: (className: string) => {
      return <DashboardIcon className={className} />
    }
  },
  {
    title: 'Schools',
    url: '/admin/schools',
    icon: (className: string) => {
      return <SchoolIcon className={className} />
    }
  }
]

export const schoolRoutes = [
  {
    title: 'Dashboard',
    url: '/school',
    icon: (className: string) => {
      return <DashboardIcon className={className} />
    }
  },
  {
    title: 'Students',
    url: '/school/students',
    icon: (className: string) => {
      return <StudentsIcon className={className} />
    }
  },
  {
    title: 'Reports',
    url: '/school/reports',
    icon: (className: string) => {
      return <ReportsIcon className={className} />
    }
  }
]
