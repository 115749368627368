import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import SchoolIcon from '@material-ui/icons/School'
import StudentsIcon from '@material-ui/icons/PeopleOutline'
import ActiveStudentsIcon from '@material-ui/icons/People'
import { getStats } from '../../services/AdminService'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconWrapper: {
      padding: 20,
      marginTop: '-20px',
      marginLeft: 20,
      position: 'absolute',
      backgroundColor: 'green',
      color: 'white',
      borderRadius: 4,
      boxShadow: '2px 2px 4px #ccc'
    }
  })
)

const Dashboard = () => {
  const classes = useStyles()
  const [dashboardStats, setDashboardStats] = useState({
    schoolsCount: 0,
    enrolledStudents: 0,
    activeStudents: 0
  })

  useEffect(() => {
    getStats().then(newStats => {
      console.log('newStats:', newStats)
      setDashboardStats(newStats)
    })
  }, [])

  return (
    <div>
      <Box mb={6}>
        <Typography variant="h5">Dashboard</Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <SchoolIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  School Partners
                </Typography>
                <Typography variant="h3" align="right">
                  {dashboardStats.schoolsCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <StudentsIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  Students Enrolled
                </Typography>
                <Typography variant="h3" align="right">
                  {dashboardStats.enrolledStudents}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card>
              <div className={classes.iconWrapper}>
                <ActiveStudentsIcon />
              </div>
              <CardContent>
                <Typography align="right" color="textSecondary">
                  Active Students
                </Typography>
                <Typography variant="h3" align="right">
                  {dashboardStats.activeStudents}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default Dashboard
