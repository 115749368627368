import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router'
import AdminLayout from '../../components/layout/AdminLayout'
import { AppContext } from '../../components/states/AppContextProvider'
import { UserRole } from '../../models/UserProfile'
import Dashboard from './Dashboard'
import Schools from './Schools'

const Admin = () => {
  const appState = useContext(AppContext).state
  const history = useHistory()

  useEffect(() => {
    if (!history) return

    if (!appState.user) {
      // Redirect to admin login page if user is not logged in
      history.replace('/admin/login')
    } else if (appState.userRole && appState.userRole !== UserRole.admin) {
      // Redirect to home page if not an admin
      history.replace('/')
    }
  }, [appState.user, appState.userRole, history])

  return (
    <AdminLayout role={UserRole.admin}>
      <Switch>
        <Route exact path="/admin" component={Dashboard} />
        <Route path="/admin/schools" component={Schools} />
      </Switch>
    </AdminLayout>
  )
}

export default Admin
